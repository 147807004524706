import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Accueil from './Components/Pages/Accueil';
import Diagnostique from './Components/Pages/Diagnostique';
import Resultats from './Components/Pages/Resultats';
import Journal from './Components/Pages/Journal';
import Wrapper from './Components/Wrapper/Wrapper';
import Objectifs from './Components/Pages/Journal/Objectifs';
import Explore from './Components/Pages/Journal/Explore';
import Plan from './Components/Pages/Journal/Plan';
import AccueilEnseignant from './Components/Pages/Enseignant/AccueilEnseignant'
import ResultatsEnseignant from './Components/Pages/Enseignant/ResultatsEnseignant'
import ExploreEnseignant from './Components/Pages/Enseignant/ExploreEnseignant'
import ObjectifsEnseignant from './Components/Pages/Enseignant/ObjectifsEnseignant'
import PlanEnseignant from './Components/Pages/Enseignant/PlanEnseignant'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import constants from './constants'
import gif from './img/IQ5_Animation_Loading_Gris.gif'
import { withAITracking } from 'react-appinsights';
import Portfolio from './Components/Pages/Portfolio'

class WhoAmI extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    let auth = this.props.auth;
    this.props.sendWhoamiInfo(auth)
    this.props.sendUserAuth(auth)
  }

  render() {

    let view = null;

    const student = "student";
    const teacher = "teacher";

    // Checks if a given object is empty
    function isNotEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    // Renders router for student role
    if(isNotEmpty(this.props.whoami) && this.props.whoami.data[0].role === student) {
      view = <Router>
                          <Wrapper>
                            <Route exact path="/" render={() => <Accueil role={this.props.whoami.data[0].role} />} />
                            <Route path="/mon-diagnostique" component={Diagnostique} />
                            <Route path="/mes-resultats-et-objectifs" component={Resultats} />
                            <Switch>
                              <Redirect exact from='/mon-journal-d-entrainement' to='/mon-journal-d-entrainement/objectifs-smart'/>
                              <Route exact path="/mon-journal-d-entrainement" component={Journal} />
                            </Switch>
                            <Route path="/mon-journal-d-entrainement/objectifs-smart" component={Objectifs} />
                            <Route path="/mon-journal-d-entrainement/j-explore-je-choisis" component={Explore} />
                            <Route path="/mon-journal-d-entrainement/plan-d-entrainement" component={Plan} />
                            <Route path="/portfolio" component={Portfolio} />
                          </Wrapper>
                        </Router>;
    // Renders router for teacher role
    } else if(isNotEmpty(this.props.whoami) && this.props.whoami.data[0].role === teacher){
      view = <Router>
                          <Wrapper>
                            <Route exact path="/" render={() => <AccueilEnseignant role={this.props.whoami} />} />
                            <Route exact path="/:name/Resultats" component={ResultatsEnseignant} />
                            <Route exact path="/:name/Objectifs" component={ObjectifsEnseignant} />
                            <Route exact path="/:name/Explore" component={ExploreEnseignant} />
                            <Route exact path="/:name/Plan" component={PlanEnseignant} />
                          </Wrapper>
                        </Router>;
    // Renders the loading icon
  } else if (isNotEmpty(this.props.whoami) && this.props.whoami.data[0].role === "other") {
      view = <div className="d-flex flex-column justify-content-center text-center" style={{height:"100vh"}}>
              <i className="fas fa-5x fa-exclamation-triangle mb-5"></i>
              <h1 className="my-0 mx-auto">Une erreur s'est produite!</h1>
              <p>Il semble que vous n'ayez pas les accès nécessaires. Veuillez contacter <a href="mailto:santepremierplan@lecentrefranco.ca">santepremierplan@lecentrefranco.ca</a>.</p>
             </div>;
  } else {
      view = <div className="d-flex justify-content-center" style={{height:"100vh"}}>
              <img className="align-self-center img-fluid" width="300px" src={gif} alt="" />
             </div>;
    }

    return view;

  }

}

function mapStateToProps(state) {
  return {
    whoami: state.whoami,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {

    sendWhoamiInfo: (auth) => {
      axios.get(process.env.REACT_APP_USERAPI, {
        headers: {
          Authorization: 'Bearer ' + auth.accessToken
        }
      }).then(res => {
        dispatch({ type: constants.SET_WHOAMI, payload: res })
      }).catch(function (error) {
        if(error.response.status === 401) {
          auth.signout()
        }
      })

    },

    sendUserAuth: (auth) => {
      dispatch({ type: constants.SET_USERAUTH, payload: auth })
    }

  }
}

WhoAmI.propTypes = {
  whoami: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  sendWhoamiInfo: PropTypes.func.isRequired,
  sendUserAuth: PropTypes.func.isRequired
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(WhoAmI));
