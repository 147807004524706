import React from 'react'
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const StudentList = (props) => {
  // Renders the list of courses with all the students registered to those courses
  return(
    <div className="my-4">
      <div className="accordion" id={`accordionExample${props.index}`}>
        <div className="card mb-2">
          <div className="card-header p-0" id={`heading${props.index}`}>
            <span className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapse${props.index}`} aria-expanded="false"controls={`collapse${props.index}`}>
              <p className="m-0 float-left">{props.course.name}</p>
              <span className="surveySectionGrey fas fa-chevron-down float-right p-1"></span>
            </span>
          </div>
          <div id={`collapse${props.index}`} className={`collapse ${(props.index === 0) ? "show" : ""}`} aria-labelledby={`heading${props.index}`}parent={`#accordionExample${props.index}`}>
            <div className="card-body pt-0 pb-3 px-3">
              {/* Passing the student name, last name and id to the student specific route */}
              {props.course.students.map((student, index) => <p className="py-2 m-0" key={index}><Link to={{
                pathname: `/${student.firstName+student.lastName}/Resultats`,
                state: {
                  studentFirstName: student.firstName,
                  studentLastName: student.lastName,
                  studentId: student.id
                }
              }}>{student.firstName} {student.lastName}</Link></p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAITracking(StudentList);
