import React, { Component } from 'react'
import Wrapper from '../../Wrapper/Wrapper';
import { connect } from 'react-redux'
import constants from '../../../constants'
import axios from 'axios'
import moment from 'moment'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'

class PlanEnseignantResults extends Component {

  constructor(props){
    super(props)

    this.activeTabHandler = this.activeTabHandler.bind(this)
    this.createComment = this.createComment.bind(this)
  }

  activeTabHandler(e) {
    // Gets clicked tab
    let target = e.target.id;
    // Gets all clickable tabs by the class .nav-link
    let items = document.querySelectorAll('.nav-link');
    // Looping through all clickable tabs
    for(var i=0; i<items.length; i++){
      if(items[i].id !== target) {
        // If the item in the loop is not the same as the clicked element, we remove the active class
        items[i].classList.remove("active");
      }
    }

  }

  createComment(e) {

    // Getting week number
    let week = e.target.name;

    // Setting id with week
    let id = "semaineComment"+week;

    // getting value of comment by id
    let comment = document.getElementById(id).value;

    // Getting a copy results from redux
    let results = JSON.parse(JSON.stringify(this.props.plan.results));

    // Getting last plan results (Current student's plan)
    let lastRes = results[results.length-1];

    // Pushing the new comment in the copy
    lastRes.data[week].commentaires.push({
      date: Date.now(),
      comment: comment
    })

    // Setting the last result of the copy as the new result with the comment inside
    for(var i=results.length-1; i>=results.length-1; i--) {
      results[i] = lastRes
    }

    // Getting access token from redux store
    let accessToken = this.props.auth.accessToken;

    // Calling action to put data in the redux store and database
    this.props.createNewComment(accessToken, this.props.studentId, results)

    document.getElementById(id).value = ""

  }

  render(){

    // Returns last plan from database which is the student's current plan
    let lastResults = this.props.plan.results[this.props.plan.results.length-1]

    let view = null;

    if(this.props.plan.results !== null) {

      // Renders current plan
      if(this.props.results && this.props.plan.results.length >= 1) {
        view = <Wrapper>
        <div className="plan-formSection my-4">
          <div className="row px-3">

            {/* Renders the week navigation for the plan */}
            <nav className="nav nav-plan-week nav-pills nav-justified w-100" id="pills-tab">

                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link active" id="tab-semaine1" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine1" aria-controls="semaine1">Semaine 1</a>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link" id="tab-semaine2" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine2" aria-controls="semaine2" >Semaine 2</a>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link" id="tab-semaine3" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine3" aria-controls="semaine3" >Semaine 3</a>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link" id="tab-semaine4" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine4" aria-controls="semaine4" >Semaine 4</a>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link" id="tab-semaine5" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine5" aria-controls="semaine4" >Semaine 5</a>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <a className="nav-item nav-link" id="tab-semaine6" onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine6" aria-controls="semaine5" >Semaine 6</a>
                </div>

            </nav>
          </div>

          <div className="row px-3">
            <div className="tab-content w-100" id="pills-tabContent">

            {/* Renders the week panels */}
            {
              this.props.results.data.map((item,index) =>
                <div key={index} className={`tab-pane fade${index === 0 ? " show active" : ""}`} id={`semaine${index+1}`} role="tabpanel" aria-labelledby={`semaine${index+1}`}>
                  <div className="col-12 tab-content p-4">

                    {/* Renders the data for each day of the current week */}
                    {Object.keys(item).map((key,i) =>
                      item[key] === item["commentaires"] ? "" :
                      <div className="row my-4" key={i}>
                        <div className="col-12">

                           <div className="form-group">
                             <label htmlFor={`semaine${index}-jour${i}`}>{item[key].date}</label>
                             <div className="input-group mb-3">
                               <textarea name={`jour${i}`} className="form-control" id={`semaine${index}-jour${i}`} aria-label="Text input with checkbox" disabled value={item[key].text}></textarea>
                               <div className="input-group-append">
                                 <div className="input-group-text">
                                   <div className="custom-control custom-checkbox">
                                     <input checked={item[key].checked ? true : false} type="checkbox" aria-label={`Complété la journée ${i} de la semaine ${index}`} id={`semaine${index}-jour${i}-check`} name={`semaine${index}-jour${i}-check`} className="custom-control-input" disabled />
                                     <label className="custom-control-label" htmlFor={`semaine${index}-jour${i}-check`} ></label>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>

                        </div>
                      </div>
                    )}

                    {/* Renders comments for the current week if there are any */}
                    <div className="row my-4 mx-1 commentSection">
                      <div className="col-12 py-4">

                        {lastResults.data[index].commentaires.length !== 0 ?

                          lastResults.data[index].commentaires.map((commentaire,numero) =>
                          <Wrapper key={numero}>
                            <p className="m-0"><strong>{moment(commentaire.date).format('LL')} à {moment(commentaire.date).format('HH:mm')}</strong></p>
                            <p>{commentaire.comment}</p>
                          </Wrapper>)
                           : "Aucun commentaire à afficher."}

                      </div>
                      <div className="col-12">

                        {/* Renders form for leaving a comment on a student's week */}
                        <div className="form-group">
                          <label htmlFor={`semaineComment${index}`}><i className="fas fa-comment mr-2"></i> Commentaire</label>
                          <div className="input-group mb-3">
                            <textarea name={index} rows="3" className="form-control" id={`semaineComment${index}`} aria-label="Text input with checkbox" ></textarea>
                            <div className="input-group-append">
                              <button className="btn btn-outline-purple" type="button" id={`button-send${index}`} name={index} onClick={(e) => this.createComment(e)}>Publier</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              )
            }

            </div>
          </div>
        </div>
        </Wrapper>
      } else {
        view = <div className="my-4">
                <div className="box my-3 text-center">
                  Cet étudiant n'a pas encore créé de plan d'entrainement.
                </div>
              </div>
      }
    } else {
      view = <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />
    }


    return(
      <Wrapper>
        {view}
      </Wrapper>
    )
  }

}

function mapStateToProps(state) {
  return {
    plan: state.plan,
    auth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createNewComment: (accessToken, studentId, results) => {
      axios.put(process.env.REACT_APP_STORAGEAPI+'journal', results, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          "For-Student": studentId
        }
      }).then(res => {
        dispatch({ type: constants.CREATE_PLAN_COMMENT, payload: results })
      }).catch(function (error) {
      });

    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PlanEnseignantResults);
