import React from 'react'
import moment from 'moment';
import Wrapper from '../../Wrapper/Wrapper';

const PlanHistory = (props) => {
  return (
    <div className="my-4">
      {/* Renders the plan number and the date the plan was created */}
      <p className="text-right mb-2 fontSize500">Plan {props.results.planNumber} - {moment(props.results.timestamp).format("LL")}</p>
      <div className="accordion" id={`accordionExample${props.index}`}>
        {/* Renders the data for every day of the week for each week */}
        {props.results.data.map(function(item, i){
          return <Wrapper key={i}>
                    <div className="card mb-2">
                      <div className="card-header p-0" id={`heading${props.index}${i}`}>
                        <button className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapse${props.index}${i}`} aria-expanded="false" aria-controls={`collapse${props.index}${i}`}>
                          <p className="m-0 float-left">Semaine {i+1}</p>
                          <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
                        </button>
                      </div>
                      <div id={`collapse${props.index}${i}`} className={`collapse ${(i === 0) ? "show" : ""}`} aria-labelledby={`heading${props.index}${i}`} data-parent={`#accordionExample${props.index}`}>
                        <div className="card-body pt-0 pb-3 px-3">
                          <table className="table m-0">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" className="w-25">Date</th>
                                <th scope="col" className="w-75">Exercices</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>{item.jour1.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour1.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour1.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour1-check-${props.index}-${i}`} disabled />
                                        <label className="custom-control-label" htmlFor={`jour1-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour1.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour1-check-${props.index}-${i}`} disabled />
                                         <label className="custom-control-label" htmlFor={`jour1-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour1.date}`}></label>
                                       </div>}
                                     </div>
                                   </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour2.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour2.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour2.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour2-check-${props.index}-${i}`} disabled />
                                        <label className="custom-control-label" htmlFor={`jour2-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour2.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour2-check-${props.index}-${i}`} disabled />
                                         <label className="custom-control-label" htmlFor={`jour2-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour2.date}`}></label>
                                       </div>}
                                     </div>
                                   </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour3.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour3.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour3.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour3-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité complété" />
                                        <label className="custom-control-label" htmlFor={`jour3-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour3.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour3-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité non complété" />
                                         <label className="custom-control-label" htmlFor={`jour3-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour3.date}`}></label>
                                       </div>}
                                     </div>
                                   </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour4.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour4.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour4.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour4-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité complété" />
                                        <label className="custom-control-label" htmlFor={`jour4-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour4.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour4-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité non complété" />
                                         <label className="custom-control-label" htmlFor={`jour4-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour4.date}`}></label>
                                       </div>}
                                     </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour5.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour5.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour5.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour5-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité complété" />
                                        <label className="custom-control-label" htmlFor={`jour5-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour5.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour5-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité non complété" />
                                         <label className="custom-control-label" htmlFor={`jour5-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour5.date}`}></label>
                                       </div>}
                                     </div>
                                   </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour6.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour6.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour6.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour6-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité complété" />
                                        <label className="custom-control-label" htmlFor={`jour6-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour6.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour6-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité non complété" />
                                         <label className="custom-control-label" htmlFor={`jour6-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour6.date}`}></label>
                                       </div>}
                                     </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{item.jour7.date}</th>
                                <td>
                                  <div className="row">
                                    <div className="col-9">
                                      {item.jour7.text}
                                    </div>
                                    <div className="col-3 text-center">
                                    {item.jour7.checked ?
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" checked className="custom-control-input" id={`jour7-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité complété" />
                                        <label className="custom-control-label" htmlFor={`jour7-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour7.date}`}></label>
                                      </div> :
                                       <div className="custom-control custom-checkbox">
                                         <input type="checkbox" className="custom-control-input" id={`jour7-check-${props.index}-${i}`} disabled tabIndex="-1" aria-label="Activité non complété" />
                                         <label className="custom-control-label" htmlFor={`jour7-check-${props.index}-${i}`} aria-label={`Activités du ${item.jour7.date}`}></label>
                                       </div>}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                 </Wrapper>
        })}
      </div>
    </div>
  )
}

export default PlanHistory;
