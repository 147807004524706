import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../Wrapper/Wrapper';
import ResultsList from '../ResultsList/ResultsList';
import { withAITracking } from 'react-appinsights';

const HistorySelect = (props) => {
  let dateSelected = [];

  // On checkbox change event
  const handleCheckboxChange = (e) => {
    // Get the checkbox the user clicked on
    let item = document.getElementById(e.target.id);
    // Gets all checkboxes by className
    let items = document.querySelectorAll('.checkboxDate');
    // Setting a counter
    let count = 0;
    // Looping through all checkboxes
    for(var i = 0; i < items.length; i++){
      // Adding 1 to counter if checkbox is checked
      if(items[i].checked) {
        count += 1;
      }
    }
    if(item.checked) {
      // Check if the counter is greater than 3 when the user checks a checkbox
      if(count > 3){
        // Uncheck the checkbox
        item.checked = false;
        // Sending message to user saying he can select no more than 3 dates
        alert('Vous ne pouvez sélectionner plus que trois dates.');
      } else {
        // If the counter is not grater than 3, we push that date in the array
        dateSelected.push(e.target.id);
      }
    } else {
      // If the onChange event is an uncheck, we remove the item from the array
      dateSelected = dateSelected.filter(item => item !== e.target.id);
    }
  }

  return (
    <Wrapper>
      {/* Rendering list of all results with checkbox containing an onChange event (handleCheckboxChange) */}
      {(props.results !== []) ? props.results.map((item, index) => <ResultsList results={item} index={index} key={index} checkbox={item} checked={handleCheckboxChange} />) : ""}
      {/* Button to show the chart with the selected dates */}
      <div className="row">
        <div className="col-12 col-sm-6 order-1 order-sm-2">
          <button className="btn btn-outline-purple my-3 my-sm-4 w-100" onClick={(event) => props.clicked(dateSelected)}>Afficher</button>
        </div>
        <div className="col-12 col-sm-6 order-2 order-sm-1">
          <button className="btn btn-outline-purple px-3 my-3 my-sm-4 w-100" onClick={props.return}><i className="fas fa-chevron-left mr-2"></i> Revenir aux résultats</button>
        </div>
      </div>
    </Wrapper>
  )
}

HistorySelect.propTypes = {
  results: PropTypes.array.isRequired
}

export default withAITracking(HistorySelect);
