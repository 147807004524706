import React, { Component } from 'react';
import AppNav from '../AppNav/AppNav';
import Instructions from '../Instructions/Instructions';
import Wrapper from '../Wrapper/Wrapper';
import { Link } from "react-router-dom";
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import surveyDiagnostique from '../../surveys/surveyDiagnostique'
import { connect } from 'react-redux'
import constants from '../../constants'
import moment from 'moment';
import axios from 'axios';
import gif from '../../img/IQ5_Animation_Loading_Gris.gif'
import { withAITracking } from 'react-appinsights';


const diagnostique = "Diagnostique";
class Diagnostique extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surveyComplete: false
    }
    this.onComplete = this.onComplete.bind(this);
    this.resetDiagnostique = this.resetDiagnostique.bind(this);
  }

  componentWillMount() {
    // Surveyjs preferences
    Survey.Survey.cssType = "bootstrap";
    Survey.defaultBootstrapCss.navigationButton = "btn";
    Survey.defaultBootstrapCss.navigation.complete = "diagnostiqueCompleteBtn";
    Survey.defaultBootstrapCss.navigation.prev = "diagnostiquePrevBtn";
    Survey.defaultBootstrapCss.navigation.next = "diagnostiqueNextBtn";
  }

  componentDidMount() {

    // Retrieve user authentication info
    let accessToken = this.props.userAuth.accessToken;
    // Calling function to get all Diagnostique in database
    this.props.getResults(accessToken);
  }

  onComplete(survey) {

    // Setting Surveyjs question constants
    const sectionName = ["item1","item2","item3","item4","item5"];
    const sectionPoints = [1,2,3,4,5]
    const s1Q = ["question1","question2","question3","question4","question5","question6","question7"];
    const s2Q = ["question8","question9","question10","question11","question12","question13"];
    const s3Q = ["question14","question15","question16","question17","question18","question19","question20"];

    // Putting survey data in variable
    const d = survey.data;
    // Defining points variables for each section of survey
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;

    // Looping through survey data
    for(var key in d){
      if(d.hasOwnProperty(key)) {
        // Checking if array has current key in first section of quiz (I.E "question1")
        if(s1Q.includes(key)) {
          // Looping through number of answers (Always 5 possible answers -> sectionName array)
          for(var i=0; i<5; i++){
            // Checking if key is equal to the selected answer in sectionName array
            if(d[key] === sectionName[i]){
              // Adding points according to selected answer
              s1 += sectionPoints[i];
            }
          }
          // Checking if array has current key in section section of quiz (I.E "question8")
        } else if(s2Q.includes(key)) {
          // Looping through number of answers (Always 5 possible answers -> sectionName array)
          for(var j=0; j<5; j++){
            // Checking if key is equal to the selected answer in sectionName array
            if(d[key] === sectionName[j]){
              // Adding points according to selected answer
              s2 += sectionPoints[j];
            }
          }
          // Checking if array has current key in section section of quiz (I.E "question14")
        } else if(s3Q.includes(key)) {
          // Looping through number of answers (Always 5 possible answers -> sectionName array)
          for(var k=0; k<5; k++){
            // Checking if key is equal to the selected answer in sectionName array
            if(d[key] === sectionName[k]){
              // Adding points according to selected answer
              s3 += sectionPoints[k];
            }
          }
        }
      }
    }

    // Copying the data from diagnostique in redux store in new variable
    let newData = [...this.props.diagnostique.results];

    // Checking if there is 1 or more results in diagnostique state
    if(newData.length >= 1) {
      // Looping through the data getting only the last result
      for(var n = newData.length - 1; n >= newData.length - 1; n--) {
        // Putting last result timestamp into a date variable
        let lastItemDate = moment(newData[n].timestamp).format("LL");
        // Comparing the last result data with todays date
        if(lastItemDate === moment().format('LL')) {
          // If last result date is the same as todays' date, we replace that result with the new result
          newData[n] = {
            timestamp: Date.now(),
            surveyPoints: [s1,s2,s3]
          };
        } else {
          // if last result is not the same as todays' date, we push the new result
          newData.push(
            {
              timestamp: Date.now(),
              surveyPoints: [s1,s2,s3]
            }
          )
        }
      }
    } else {
      // If there is no results is the state, we push the new result
      newData.push(
        {
          timestamp: Date.now(),
          surveyPoints: [s1,s2,s3]
        }
      )
    }

    // Setting new object to send in redux store
    let newState = {
      surveyComplete: true,
      results: newData
    }

    // initializing variables containing the accessToken and the bonjour_person_id
    let accessToken = this.props.userAuth.accessToken;
    let bonjour_person_id = this.props.userAuth.profile.bonjour_person_id;

    // Calling action to send the new or updated results
    this.props.createNewDiagnostique(newState, accessToken, bonjour_person_id)

    // Setting state.surveyComplete to true for loading purposes
    this.setState({ surveyComplete: true })
  }

  resetDiagnostique() {
    this.setState({ surveyComplete: false })
    this.props.resetDiagnostique()
  }

  render() {

    // initializing survey js
    var survey = new Survey.Model(surveyDiagnostique);
    survey.requiredText = "";
    survey.showPreviousButton = true

    // When data as been sent to database successfully, we render the following message
    let surveyCompleted = <Wrapper>
                          <div className="box my-3">
                            <p className="completedSurveyText m-0">Vos réponses ont été sauvegardées.</p>
                          </div>
                          <div className="col-12 text-center my-5">
                            <span className="fas fa-check-circle fa-3x iconSurveySuccess"></span>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6 text-center text-md-right px-2 mb-4">
                              <button className="btn btn-outline-purple text-center w-75" onClick={this.resetDiagnostique}>Refaire le diagnostique</button>
                            </div>
                            <div className="col-12 col-md-6 text-center text-md-left px-2">
                              <Link className="btn btn-outline-purple text-center w-75" to="/mes-resultats-et-objectifs">Mes résultats et mes objectifs</Link>
                            </div>
                          </div>
                        </Wrapper>

    return (
      <Wrapper>
        <AppNav section={diagnostique} />
        <main className="container" id="main">
          {(this.props.diagnostique.surveyComplete) ?
            surveyCompleted :
            this.state.surveyComplete ?
              <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" /> :
              <Wrapper>
                <Instructions>
                  <p className="m-0">Pour chaque énoncé, coche le cercle qui représente le mieux ton vécu actuel. Par la suite, tu feras une analyse de tes résultats en vue de préparer ton plan personnel d’entraînement.</p>
                </Instructions>
                <Survey.Survey model={survey} onComplete={this.onComplete} />
              </Wrapper>}
        </main>
      </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    diagnostique: state.diagnostique,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createNewDiagnostique: (data, accessToken, bonjour_person_id) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "For-Student": bonjour_person_id,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'diagnostique', data.results, {headers: headers}).then(res => {
        dispatch({ type: constants.CREATE_DIAGNOSTIQUE, payload: data })
      }).catch(function (error) {
      });
    },
    resetDiagnostique: () => {
      dispatch({ type: constants.RESET_DIAGNOSTIQUE })
    },
    getResults: (accessToken) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'diagnostique', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {
        dispatch({ type: constants.GET_RESULTS, payload: res.data })
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({ type: constants.GET_RESULTS, payload: [] })
        }
      });
    }
  }
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(Diagnostique));
