import React from 'react';

const Instructions = (props) => {

  return (
        <div className="col-12 instructionsBox p-3 my-3" id="topText">
          {props.children}
        </div>
  )
}

export default Instructions;
