import React from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import { withAITracking } from 'react-appinsights';

// Renders modal content in plan d'entrainement
const ModalContentPlan = () => {
  return (
    <Wrapper>
      <div className="row mb-3">
        <div className="col-6">
          <span className="far fa-question-circle fa-2x purpleColor"></span>
        </div>
        <div className="col-6 text-right">
          <button type="button" className="btn btn-outline-purple" data-dismiss="modal" aria-label="Fermer la boite de dialogue">Fermer</button>
        </div>
      </div>
      <div className="box mb-4">
        <div className="row">
            <div className="col-6 my-auto">
                <h2 className="surveySectionTitle d-inline px-2">Consignes</h2>
            </div>
            <div className="col-6 text-right">
                <a className="btn p-0 my-auto" href="https://lien.cforp.ca/20939" target="_blank" rel="noopener noreferrer">
                  <p className="d-inline purpleColor">Fiche d'entrainement</p>
                  <span className="fas fa-file-pdf p-1 purpleColor px-2"></span>
                </a>
                <a className="btn p-0 my-auto" href="https://lien.cforp.ca/20940" target="_blank" rel="noopener noreferrer">
                  <p className="d-inline purpleColor">Fiche d'entrainement - Gabarit</p>
                  <span className="fas fa-file-pdf p-1 purpleColor px-2"></span>
                </a>
            </div>
        </div>
      </div>
      <div className="col-12 p-0">
        <div className="accordion" id="accordionExample">

          <div className="card mb-2">
            <div className="card-header p-0" id="headingOne">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                <p className="m-0 float-left">Échauffement</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                <p>Il est important de faire un échauffement au début de chaque séance d’entraînement. Alors, la première composante à inclure dans ton programme journalier est « Échauffement ». Il n'est pas obligatoire d’écrire les exercices que tu feras. </p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingTwo">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <p className="m-0 float-left">Cardiorespiratoire</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">
                <p className="mb-4">Écris les activités de ton programme d’endurance cardiorespiratoire. Au besoin, indique la durée, l’intensité et le ratio pour chaque activité sélectionnée.</p>
                <p>D = Durée (p. ex., 30 min)</p>
                <p>I = Intensité (p. ex., 75 %)</p>
                <p>R = Ratio (p. ex., 5 : 1)</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingThree">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <p className="m-0 float-left">Musculation</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div className="card-body">
                <p className="mb-4">Dresse la liste des exercices de ton programme d’endurance musculaire. Au besoin, indique le poids, le nombre de séries, le nombre de répétitions et le tempo qui s'applique à chaque exercice sélectionné.</p>
                <p>P = Poids (p. ex., 18 kg ou 40 lbs)</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingFour">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <p className="m-0 float-left">Flexibilité</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div className="card-body">
                <p>Assure-toi d’incorporer des exercices de flexibilité à ton programme d’entraînement. Tu n’as pas à écrire chacun des exercices que tu feras, mais inscris « Flexibilité » dans ton programme journalier à titre de rappel.</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingFive">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <p className="m-0 float-left">Retour au calme</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
              <div className="card-body">
                <p>Inscris « Retour au calme » dans ta liste de composante pour ton programme d’entraînement journalier. Toutefois, tu n’as pas à écrire les exercices que tu feras.</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingSix">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <p className="m-0 float-left">Nutrition</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
              <div className="card-body">
                <p className="mb-4">Écris l’abréviation de chaque catégorie d’aliments du Guide alimentaire canadien. Indique si tu as consommé la bonne proportion d’aliments pour chacune d'elle. Ajoute à la liste la catégorie « Autres aliments » et indique si tu as consommé des aliments de ce type (p. ex., croustilles, tartelette au beurre). Finalement, ajoute la rubrique « Eau » et indique la quantité d’eau consommée (nombre de verres de 200 ml).</p>
                <p>LF = Légumes et fruits (oui ou non)</p>
                <p>AP = Aliments protéinés (oui ou non)</p>
                <p>GR = Aliments à grains entiers (oui ou non)</p>
                <p>AA = Autres aliments (oui ou non)</p>
                <p>EAU = Eau (p. ex., 8 verres)</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingSeven">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <p className="m-0 float-left">Sommeil</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
              <div className="card-body">
                <p className="mb-4">Écris le nombre d’heures de sommeil.</p>
                <p>Sommeil : (p. ex., 8 h)</p>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header p-0" id="headingEight">
              <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <p className="m-0 float-left">Mes objectifs (liés à mes habitudes saines et à mon attitude)</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
              <div className="card-body">
                <p className="mb-4">Écris tes objectifs SMART liés à tes habitudes saines ainsi qu’à tes expériences et à ton attitude, le cas échéant. Inspire-toi des notes que tu as prises lors de ta recherche pour préciser l’action que tu mettras en œuvre pour atteindre ton objectif. </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Wrapper>
  )
}

export default withAITracking(ModalContentPlan);
