import React from 'react';
import { Link } from "react-router-dom";
import Wrapper from '../Wrapper/Wrapper'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAITracking } from 'react-appinsights';

const teacher = "teacher";

const SideNav = (props) => {

  // Opens side nav
  const openNavHandler = () => {
    document.querySelector("#sidebar").classList.add('active');
    document.querySelector(".overlay").classList.add('active');
  }

  // Closes side nav
  const closeNavHandler = () => {
    document.querySelector("#sidebar").classList.remove('active');
    document.querySelector(".overlay").classList.remove('active');
  }

  return (
      <Wrapper>
        {/* Same code as for the NavBar component. This is the sidenav for mobile devices */}
        <div className="overlay" onClick={closeNavHandler}></div>

        <nav className="navbar navbar-expand-lg navbar-light bg-white d-lg-none greenBorder">
          <div className="container-fluid">
            <div className="navbar-nav mr-auto">
              <i className="fas fa-bars fa-2x" id="sidebarCollapse" onClick={openNavHandler}></i>
            </div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <p className="btn-link-grey px-0 my-auto py-2">{props.userAuth.profile.given_name} {props.userAuth.profile.family_name}</p>
              </li>
            </ul>
          </div>
        </nav>


        <nav id="sidebar" className="d-block d-lg-none">

          <div className="sidebar-header">
            <div id="dismiss" onClick={closeNavHandler}>
                <i className="fas fa-bars fa-2x"></i>
            </div>
          </div>

          {props.role !== teacher ?
          <ul className="list-unstyled components text-right">

            <li className="nav-item">
              <button type="button" className="btn"><Link to="/" className="purpleColor">Accueil<i className="fas fa-home iconStatic-bc ml-2"></i></Link></button>
            </li>

            <li className="nav-item">
              <button type="button" className="btn"><Link to="/mon-diagnostique" className="purpleColor">Mon diagnostique<i className="fas fa-chart-area iconStatic-bc ml-2"></i></Link></button>
            </li>

            <li className="nav-item">
              <button type="button" className="btn"><Link to="/mes-resultats-et-objectifs" className="purpleColor">Mes résultats et objectifs<i className="fas fa-stream iconStatic-bc ml-2"></i></Link></button>
            </li>

            <li className="nav-item">
              <button type="button" className="btn"><Link to="/mon-journal-d-entrainement" className="purpleColor">Mon journal d'entraînement<i className="far fa-calendar-check iconStatic-bc ml-2"></i></Link></button>
            </li>

            <li className="nav-item">
              <button type="button" className="btn"><Link to="/portfolio" className="purpleColor">Notes personnelles<i className="fas fa-briefcase iconStatic-bc ml-2"></i></Link></button>
            </li>
          </ul> : "" }

          <ul className="list-unstyled text-right">
            <li className="nav-item my-auto">
              <Link to="/" className="btn btn-link btn-link-grey">Retour au cours</Link>
            </li>
            <li className="nav-item my-auto">
              <button type="button" className="btn btn-link btn-link-grey" onClick={(event) => props.userAuth.signout()}>Fermer la session</button>
            </li>
          </ul>

        </nav>
      </Wrapper>
  )

}

function mapStateToProps(state) {
  return {
    userAuth: state.userAuth
  }
}

SideNav.propTypes = {
  role: PropTypes.string,
  userAuth: PropTypes.object.isRequired
}

export default withAITracking(connect(mapStateToProps)(SideNav));
