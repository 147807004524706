import React, { Component } from 'react'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux'
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';
import constants from '../../../constants'

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];
const WEEKDAYS_SHORT = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

class PlanCreate extends Component {

  constructor(props){
    super(props)

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  componentDidMount() {
    // componentDidMount() here serves as creating a new plan with today as the starting date

    // When user clicks on a new date either in the "date de début" or "date de fin" panel it will call
    // the handleDayClick() function which will then change the current date for the date the user chose

    let newplan = [];

    // Creates an empty JSON with the right format for the plan results
    let emptyResults = this.createEmptyData();

    // Setting today's date
    let today = new Date();

    // Setting new date that will serve as the end date of the plan
    let endDate = new Date();

    // Setting the end date as 41 days from now (6 weeks / plan)
    endDate.setDate(endDate.getDate() + 41);

    let weekday = ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]
    let dayCount = 0;

    let copy = JSON.parse(JSON.stringify(emptyResults))

    // Looping through each week of the newPlan object to set the formated date for each day
    for(var a=0; a<6; a++) {
      copy[a].jour1.date = weekday[moment(today).add(dayCount+0, 'd').weekday()]+" "+moment(today).add(dayCount+0, 'd').format('LL');
      copy[a].jour2.date = weekday[moment(today).add(dayCount+1, 'd').weekday()]+" "+moment(today).add(dayCount+1, 'd').format('LL');
      copy[a].jour3.date = weekday[moment(today).add(dayCount+2, 'd').weekday()]+" "+moment(today).add(dayCount+2, 'd').format('LL');
      copy[a].jour4.date = weekday[moment(today).add(dayCount+3, 'd').weekday()]+" "+moment(today).add(dayCount+3, 'd').format('LL');
      copy[a].jour5.date = weekday[moment(today).add(dayCount+4, 'd').weekday()]+" "+moment(today).add(dayCount+4, 'd').format('LL');
      copy[a].jour6.date = weekday[moment(today).add(dayCount+5, 'd').weekday()]+" "+moment(today).add(dayCount+5, 'd').format('LL');
      copy[a].jour7.date = weekday[moment(today).add(dayCount+6, 'd').weekday()]+" "+moment(today).add(dayCount+6, 'd').format('LL');
      dayCount+=7
    }

    // Creates the data and keeps track of when it was done with the timestamp
    newplan = {
      timestamp: Date.now(),
      data: copy,
      week: 1,
      saved: null,
      dates: [today,endDate]
    };

    // Save the data to the store
    this.props.saveNewPlanData(newplan)

  }

  createEmptyData() {
    // Creating empty plan results
    let emptyResults = [];
    let weekObj = {}

    let weekDays = ["jour1","jour2","jour3","jour4","jour5","jour6","jour7"]
    // Looping through 6 weeks
    for(var i=0; i<6; i++) {
      // Looping through every day of each weeks
      for(var j=0; j<7; j++) {
        // Adds an object for each day (jour1, jour2, etc.) to the weekObj object
        weekObj[weekDays[j]] = {
          text: "",
          checked: false,
          date: null
        }
      }
      // Adds a comment property for each week
      weekObj["commentaires"] = []
      // Pushing current week to the emptyResults array
      emptyResults.push(weekObj)
    }

    // Returns the empty plan results
    return emptyResults;
  }

  handleDayClick(day, e) {

    // Gets a copy of the newPlan object from the store
    let newplan = JSON.parse(JSON.stringify(this.props.plan.newPlan))

    let start = "start";

      // User clicked on a date in the Date de début panel
      if(e === start) {
          // Gets the date the user chose
          let endDate = new Date(day);

          // Returns the end date by adding 41 days to the chosen date
          endDate.setDate(endDate.getDate() + 41);

          // Sets the starting date and the ending date in the copy of the newPlan object
          newplan.dates = [day, endDate];

          let weekday = ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]

          let dayCount = 0;
          // Sets the starting date
          let thedate = new Date(day);
          // Looping through each week of the newPlan object to set the formated date for each day
          for(var j=0; j<newplan.data.length; j++) {
            newplan.data[j].jour1.date = weekday[moment(thedate).add(dayCount+0, 'd').weekday()]+" "+moment(thedate).add(dayCount+0, 'd').format('LL');
            newplan.data[j].jour2.date = weekday[moment(thedate).add(dayCount+1, 'd').weekday()]+" "+moment(thedate).add(dayCount+1, 'd').format('LL');
            newplan.data[j].jour3.date = weekday[moment(thedate).add(dayCount+2, 'd').weekday()]+" "+moment(thedate).add(dayCount+2, 'd').format('LL');
            newplan.data[j].jour4.date = weekday[moment(thedate).add(dayCount+3, 'd').weekday()]+" "+moment(thedate).add(dayCount+3, 'd').format('LL');
            newplan.data[j].jour5.date = weekday[moment(thedate).add(dayCount+4, 'd').weekday()]+" "+moment(thedate).add(dayCount+4, 'd').format('LL');
            newplan.data[j].jour6.date = weekday[moment(thedate).add(dayCount+5, 'd').weekday()]+" "+moment(thedate).add(dayCount+5, 'd').format('LL');
            newplan.data[j].jour7.date = weekday[moment(thedate).add(dayCount+6, 'd').weekday()]+" "+moment(thedate).add(dayCount+6, 'd').format('LL');
            // Adds 7 to simulate a week
            dayCount+=7
          }

          // Saves the dates in the store
          this.props.saveNewPlanData(newplan)

      // User clicked on a date in the Date de fin panel
      } else {
          // Gets the date the user chose
          let startDate = new Date(day);

          // Returns the start date by subtracting 41 days to the chosen date
          startDate.setDate(startDate.getDate() - 41);

          // Sets the starting date and the ending date in the copy of the newPlan object
          newplan.dates = [startDate, day];

          let weekday = ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]

          let dayCount = 0;
          // Sets the starting date
          let thedate = new Date(day);
          // Looping through each week of the newPlan object to set the formated date for each day
          for(var l=0; l<newplan.data.length; l++) {
            newplan.data[l].jour1.date = weekday[moment(thedate).add(dayCount+0, 'd').weekday()]+" "+moment(thedate).add(dayCount+0, 'd').format('LL');
            newplan.data[l].jour2.date = weekday[moment(thedate).add(dayCount+1, 'd').weekday()]+" "+moment(thedate).add(dayCount+1, 'd').format('LL');
            newplan.data[l].jour3.date = weekday[moment(thedate).add(dayCount+2, 'd').weekday()]+" "+moment(thedate).add(dayCount+2, 'd').format('LL');
            newplan.data[l].jour4.date = weekday[moment(thedate).add(dayCount+3, 'd').weekday()]+" "+moment(thedate).add(dayCount+3, 'd').format('LL');
            newplan.data[l].jour5.date = weekday[moment(thedate).add(dayCount+4, 'd').weekday()]+" "+moment(thedate).add(dayCount+4, 'd').format('LL');
            newplan.data[l].jour6.date = weekday[moment(thedate).add(dayCount+5, 'd').weekday()]+" "+moment(thedate).add(dayCount+5, 'd').format('LL');
            newplan.data[l].jour7.date = weekday[moment(thedate).add(dayCount+6, 'd').weekday()]+" "+moment(thedate).add(dayCount+6, 'd').format('LL');
            // Adds 7 to simulate a week
            dayCount+=7
          }
          // Saves the dates in the store
          this.props.saveNewPlanData(newplan)
      }

  }

  render() {

    let newPlan = this.props.plan.newPlan;


    return (
      <>

      {/* Panel for the stating date - User can choose a starting date for his new plan */}
      <div className="row my-4">
        <div className="col-12 col-md-5 text-center">
          <h2 className="mb-3">Date de début</h2>
          <DayPicker
            localeUtils={MomentLocaleUtils}
            locale="fr"
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            showOutsideDays
            selectedDays={newPlan === undefined ? new Date() : new Date(newPlan.dates[0])}
            onDayClick={(day, e) => this.handleDayClick(day, "start")}
            month={newPlan === undefined ? new Date() : new Date(newPlan.dates[0])}
          />
        </div>
        <div className="col-12 col-md-2 d-none d-md-flex justify-content-center align-items-center">
          <i className="fas fa-chevron-right fa-2x"></i>
        </div>
        {/* Panel for the ending date - User can choose a ending date for his new plan */}
        <div className="col-12 col-md-5 text-center">
          <h2 className="mb-3">Date de fin</h2>
          <DayPicker
            localeUtils={MomentLocaleUtils}
            locale="fr"
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            showOutsideDays
            selectedDays={newPlan === undefined ? new Date() : new Date(newPlan.dates[1])}
            onDayClick={(day, e) => this.handleDayClick(day, "end")}
            month={newPlan === undefined ? new Date() : new Date(newPlan.dates[1])}
          />
        </div>
      </div>

      </>
    )
  }

}

function mapStateToProps(state){
  return {
    plan: state.plan
  }
}

function mapDispatchToProps(dispatch){
  return {
    saveNewPlanData: (data) => {
      dispatch({ type: constants.SET_NEW_PLAN, payload: data })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCreate);
