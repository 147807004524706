import React from 'react';
import { Link } from "react-router-dom";
import AppNav from '../AppNav/AppNav';
import Instructions from '../Instructions/Instructions'
import Wrapper from '../Wrapper/Wrapper';
import { withAITracking } from 'react-appinsights';

// Images of each section
let img1 = "https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,g_south,h_700,w_1920/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_177932354.jpg";
let img2 = "https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,h_700,w_1920/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_127139898.jpg";
let img3 = "https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,h_700,w_1920/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_92561687.jpg";
let img4 = "https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,h_700,w_1920/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_108888865.jpg";

const Accueil = (props) => {

    return (
      <Wrapper>
        <AppNav section="home" role={props.role} />
        <main className="container" id="main">
          <Instructions>
            <p>Comment créer un plan personnel d’entraînement?</p>

            <p className="m-0">La marche à suivre décrite aux pages proposées ci-dessous t’aidera à cibler tes besoins en ce qui a trait à ta santé et à ton bien-être, puis à préparer un plan d’entraînement structuré et adapté à tes besoins. Rends-toi d’abord sur la page Mon diagnostique afin de remplir un sondage qui te permettra de réfléchir à ton état de santé. La page Mes résultats te permettra ensuite d’examiner et d’analyser les résultats de ton sondage pour enfin préparer ton plan d’entraînement en suivant les étapes présentées à la page Mon plan d’entraînement.</p>
          </Instructions>


          <div className="row my-md-4 my-0">
            {/* Showing button on all devices */}
            <div className="col-12 col-md-6 pl-md-3 mb-3 mb-md-0 py-1">
              <img src={img1} className="img-fluid boxShadow d-md-block d-none mb-2" alt="" />
              <Link aria-label="Page Mon diagnostique" className="btn btn-outline-purple m-0 w-100 py-2 text-md-center" to="/mon-diagnostique"><i className="fas fa-chart-area mr-2"></i>Mon diagnostique</Link>
            </div>

            {/* Showing button on all devices */}
            <div className="col-12 col-md-6 pl-md-3 mb-3 mb-md-0 py-1">
              <img src={img2} className="img-fluid boxShadow d-md-block d-none mb-2" alt="" />
              <Link aria-label="Page Mes résultats" className="btn btn-outline-purple m-0 w-100 py-2 text-md-center" to="/mes-resultats-et-objectifs"><i className="fas fa-stream mr-2"></i>Mes résultats</Link>
            </div>
          </div>
          <div className="row mb-4">
            {/* Showing button on all devices */}
            <div className="col-12 col-md-6 pl-md-3 mb-3 mb-md-0 py-1">
              <img src={img3} className="img-fluid boxShadow d-md-block d-none mb-2" alt="" />
              <Link aria-label="Page Mon journal d'entraînement" className="btn btn-outline-purple m-0 w-100 py-2 text-md-center" to="/mon-journal-d-entrainement"><i className="far fa-calendar-check mr-2"></i>Mon journal d'entraînement</Link>
            </div>

            {/* Showing button on all devices */}
            <div className="col-12 col-md-6 pl-md-3 mb-3 mb-md-0 py-1">
              <img src={img4} className="img-fluid boxShadow d-md-block d-none mb-2" alt="" />
              <Link aria-label="Page Mes notes personnelles" className="btn btn-outline-purple m-0 w-100 py-2 text-md-center" to="/portfolio"><i className="fas fa-briefcase mr-2"></i>Mes notes personnelles</Link>
            </div>
          </div>
        </main>
      </Wrapper>
    )

}

export default withAITracking(Accueil);
