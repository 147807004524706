import React, { Component } from 'react'
import PlanForm from './PlanForm'
import { connect } from 'react-redux';
import Wrapper from '../../Wrapper/Wrapper';
import gif from '../../../img/IQ5_Animation_Loading_Blanc.gif'
import moment from 'moment'
import constants from '../../../constants'

class PlanJournal extends Component {

  activeTabHandler(e) {
    // Gets clicked tab
    let target = e.target.id;
    // Gets all clickable tabs by the class .nav-link
    let items = document.querySelectorAll('.nav-link');
    // Looping through all clickable tabs
    for(var i=0; i<items.length; i++){
      if(items[i].id !== target) {
        // If the item in the loop is not the same as the clicked element, we remove the active class
        items[i].classList.remove("active");
      }
    }

    // Getting week number
    let week = e.target.name;

    this.props.setPlanWeek(week)

  }

  render() {

    let functions = null;

    // Renders the buttons below the plan data
    // More than one plan : the consulter mon historique button appears
    if(this.props.plan.results && this.props.plan.results.length > 1) {
      functions = <div className="row">
                    <div className="col-12 col-md">
                    <button type="button" className="w-100 btn btn-outline-purple px-3 mb-4" onClick={this.props.history}><i className="fas fa-history mr-2"></i> Consulter mon historique</button>
                    </div>
                    <div className="col-12 col-md">
                      <button type="button" className="w-100 btn btn-outline-purple px-3 mb-4" onClick={this.props.new}><i className="fas fa-plus mr-2"></i> Créer un nouveau plan</button>
                    </div>
                    </div>
    } else {
      functions = <div className="row">
                  <div className="col-12 col-md"></div>
                  <div className="col-12 col-md">
                    <button type="button" className="w-100 btn btn-outline-purple px-3 mb-4" onClick={this.props.new}><i className="fas fa-plus mr-2"></i> Créer un nouveau plan</button>
                  </div>
                  </div>
    }

    let loading = null;

    // Shows the loading icon when saving the plan
    // Shows a message when plan is successfully saved
    if(this.props.plan.loading === true) {
      loading = <img className="mx-auto d-block img-fluid" width="38px" src={gif} alt="" />
    } else if(this.props.plan.loading === false) {
      loading = "Votre plan d'entraînement a été enregistré!"
    } else if(this.props.plan.loading === null) {
      loading = ""
    }

    // Gets the current plan
    let lastResults = this.props.plan.results[this.props.plan.results.length-1]

    // Renders the plan d'entrainement page content
    return(
      <Wrapper>
        <div className="plan-formSection my-4">

          <div className="row px-3">
            <nav className="nav nav-plan-week nav-pills nav-justified w-100" id="pills-tab">

                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100 active" id="tab-semaine1" name={1} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine1" aria-current={this.props.plan.activeWeek === 1 ? "true" : "false"}>Semaine 1</button>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100" id="tab-semaine2" name={2} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine2" aria-current={this.props.plan.activeWeek === 2 ? "true" : "false"}>Semaine 2</button>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100" id="tab-semaine3" name={3} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine3" aria-current={this.props.plan.activeWeek === 3 ? "true" : "false"}>Semaine 3</button>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100" id="tab-semaine4" name={4} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine4" aria-current={this.props.plan.activeWeek === 4 ? "true" : "false"}>Semaine 4</button>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100" id="tab-semaine5" name={5} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine5" aria-current={this.props.plan.activeWeek === 5 ? "true" : "false"}>Semaine 5</button>
                </div>
                <div className="col-4 col-md-4 col-lg-2 px-0">
                  <button className="nav-item nav-link w-100" id="tab-semaine6" name={6} onClick={(e) => this.activeTabHandler(e)} data-toggle="pill" href="#semaine6" aria-current={this.props.plan.activeWeek === 6 ? "true" : "false"}>Semaine 6</button>
                </div>

            </nav>
          </div>

          <div className="row px-3">
            <div className="tab-content w-100" id="pills-tabContent">

              <div className="tab-pane fade show active" id="semaine1" role="tabpanel" aria-labelledby="semaine1">
                <PlanForm week={1} />
              </div>
              <div className="tab-pane fade" id="semaine2" role="tabpanel" aria-labelledby="semaine2">
                <PlanForm week={2} />
              </div>
              <div className="tab-pane fade" id="semaine3" role="tabpanel" aria-labelledby="semaine3">
                <PlanForm week={3} />
              </div>
              <div className="tab-pane fade" id="semaine4" role="tabpanel" aria-labelledby="semaine4">
                <PlanForm week={4} />
              </div>
              <div className="tab-pane fade" id="semaine5" role="tabpanel" aria-labelledby="semaine5">
                <PlanForm week={5} />
              </div>
              <div className="tab-pane fade" id="semaine6" role="tabpanel" aria-labelledby="semaine6">
                <PlanForm week={6} />
              </div>

            </div>
          </div>

          <div className="tabPanelFooter px-4 py-3 text-right">
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center justify-content-end">
                  <p className="m-0 mr-4">{loading}</p>
                  <button type="button" className="btn btnPlanSauvegarder" onClick={this.props.save}>Sauvegarder</button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="box my-4">
          <div className="col-12 my-3">
            <p className="py-2 commentairesEleves"><i className="fas fa-comment mr-2"></i> Commentaires de l'enseignante ou de l'enseignant</p>
            {lastResults.data[this.props.plan.activeWeek-1].commentaires.length !== 0 ?
              lastResults.data[this.props.plan.activeWeek-1].commentaires.map((commentaire,numero) =>
              <Wrapper key={numero}>
                <p className="m-0"><strong>{moment(commentaire.date).format('LL')} à {moment(commentaire.date).format('HH:mm')}</strong></p>
                <p>{commentaire.comment}</p>
              </Wrapper>)
               : "Aucun commentaire à afficher."}
          </div>
        </div>

        {functions}
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    plan: state.plan
  }
}

function mapDispatchToProps(dispatch){
  return {
    setPlanWeek: (week) => {
      dispatch({ type: constants.SET_PLAN_WEEK, payload: week });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanJournal);
