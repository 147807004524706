import React from 'react';
import { Link } from "react-router-dom";
import Wrapper from '../Wrapper/Wrapper'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withAITracking } from 'react-appinsights';

const teacher = "teacher";

const NavBar = (props) => {
  return (
    <Wrapper>
      <a className="skip-main" href="#main">Sauter au contenu principal</a>
      <nav className="navbar navbar-expand-lg navbar-light bg-white greenBorder d-none d-lg-flex">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        {/* If user is a student, we show the section link (Teacher has not access to those routes) */}
        {props.role !== teacher ?
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="btn btn-link" to="/">
                <i className="fas fa-home iconStatic-bc mr-2"></i>
                <p className="ml-1 align-middle d-inline">Accueil</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link" to="/mon-diagnostique">
                <i className="fas fa-chart-area iconStatic-bc mr-2"></i>
                <p className="ml-1 align-middle d-inline">Diagnostique</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link" to="/mes-resultats-et-objectifs">
                <i className="fas fa-stream iconStatic-bc mr-2"></i>
                <p className="ml-1 align-middle d-inline">Résultats</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link" to="/mon-journal-d-entrainement">
                <i className="far fa-calendar-check iconStatic-bc mr-2"></i>
                <p className="ml-1 align-middle d-inline">Journal d'entraînement</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link" to="/portfolio">
                <i className="fas fa-briefcase iconStatic-bc mr-2"></i>
                <p className="ml-1 align-middle d-inline">Notes personnelles</p>
              </Link>
            </li>
          </ul> : "" }
          {/* Dropdown menu for both student and teacher - Info and logout buttons */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <div className="btn-group">
                    <Wrapper>
                      <button id="btnGroupDrop1" type="button" className="btn btn-link btn-link-grey" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {props.userAuth.profile.given_name} {props.userAuth.profile.family_name} <span className="fas fa-caret-down"></span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <Link to="/" className="dropdown-item-link btn btn-link">Retour au cours</Link>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item-link btn btn-link" onClick={(event) => props.userAuth.signout()}>Fermer la session</button>
                      </div>
                    </Wrapper>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </Wrapper>
  )
}

function mapStateToProps(state) {
  return {
    userAuth: state.userAuth
  }
}

NavBar.propTypes = {
  role: PropTypes.string,
  userAuth: PropTypes.object.isRequired
}

export default withAITracking(connect(mapStateToProps)(NavBar));
