import React from 'react';
import { Link } from "react-router-dom";
import AppNav from '../AppNav/AppNav';
import Instructions from '../Instructions/Instructions'
import Wrapper from '../Wrapper/Wrapper';
import { withAITracking } from 'react-appinsights';

// Rendering section buttons on journal page
const Journal = (props) => {
  return (
    <Wrapper>
      <AppNav section="Journal" auth={props.auth} />
      <div className="container">
        <Instructions />
        <div className="row mt-4">
          <div className="col-8 col-md-4 mx-auto mb-3">
            <Link className="btn btn-survey sectionRed text-center m-0 h-100 w-100 fontSize500" to="/mon-journal-d-entrainement/objectifs-smart">Objectifs SMART</Link>
          </div>
          <div className="col-8 col-md-4 mx-auto mb-3">
            <Link className="btn btn-survey sectionRed text-center m-0 h-100 w-100 fontSize500" to="/mon-journal-d-entrainement/j-explore-je-choisis">J'explore, je choisis</Link>
          </div>
          <div className="col-8 col-md-4 mx-auto mb-3">
            <Link className="btn btn-survey sectionRed text-center m-0 h-100 w-100 fontSize500" to="/mon-journal-d-entrainement/plan-d-entrainement">Plan d'entraînement</Link>
          </div>
        </div>

      </div>
    </Wrapper>
  )

}

export default withAITracking(Journal);
