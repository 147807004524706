import React from "react";
import PropTypes from 'prop-types';
import Wrapper from "../Wrapper/Wrapper"
import { withAITracking } from 'react-appinsights';

const JournalActivities = (props) => {

  return(
    <div className="my-4">
      <p className="text-right mb-2 fontSize500">{props.results.date}</p>
      <div className="accordion" id={`accordionExample${props.index}`}>

        {/* Renders collapsible element for each sections of the activity page */}
        {props.results.section.map(function(object, i){
          return <Wrapper key={i}>
                    <div className="card mb-2">
                      <div className="card-header p-0" id={`heading${props.index}${i}`}>
                        <button className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapse${props.index}${i}`} aria-expanded="false" aria-controls={`collapse${props.index}${i}`}>
                          <p className="m-0 float-left">{props.results.section[i].title}</p>
                          <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
                        </button>
                      </div>
                      <div id={`collapse${props.index}${i}`} className={`collapse ${(i === 0) ? "show" : ""}`} aria-labelledby={`heading${props.index}${i}`} data-parent={`#accordionExample${props.index}`}>
                        <div className="card-body pt-0 pb-3 px-3">
                          <p className="fontSize500 my-1">Ressources consultées :</p>
                          {props.results.section[i].ressources !== "" ? <p className="mb-3">{props.results.section[i].ressources}</p> : <p className="mb-3 naText">N/A</p>}
                          <p className="fontSize500 mb-1">{i === 5 ? "Choix d'actions :" : "Choix d'activités et d'exercices :"}</p>
                          <p className="m-0">{props.results.section[i].activities}</p>
                        </div>
                      </div>
                    </div>
                 </Wrapper>
        })}

      </div>
    </div>
  )
}

JournalActivities.propTypes = {
  results: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default withAITracking(JournalActivities);
