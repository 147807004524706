import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import propTypes from 'prop-types';
import { UserManager } from 'oidc-client';

class OidcConnect extends React.Component {
  constructor(props) {
      super(props);

      // Forward declarations, to be assigned later
      this.origin = null;
      this.user = null;
      this.didInitialAttempt = false;
      this.error = null;
  }

  componentWillMount() {
    // Determine the URL origin, save it since we'll need it later
    this.origin = window.location.protocol + "//" + window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');

    // Clone settings from props, set default property values
    this.settings = Object.assign({}, this.props.settings);
    this.settings.redirect_path = this.settings.redirect_path || OidcConnect.defaultProps.settings.redirect_path;
    this.settings.post_logout_redirect_path = this.settings.post_logout_redirect_path || OidcConnect.defaultProps.settings.post_logout_redirect_path;
    this.settings.post_logout_path = this.post_logout_path || OidcConnect.defaultProps.settings.post_logout_path;
    this.settings.response_type = this.settings.response_type || OidcConnect.defaultProps.settings.response_type;

    // Set a few extra properties that are required by UserManager
    this.settings.redirect_uri = this.origin + this.settings.redirect_path;
    this.settings.post_logout_redirect_uri = this.origin + this.settings.post_logout_redirect_path;

    this.userManager = new UserManager(this.settings);
  }

  /** Creates and returns the object passed to the render function */
  getSession() {
    const self = this;
    if (!self.user) {
      // No 'user' is assigned, so the user is not yet authenticated.
      return {
        // Contains any exceptions caught during authentication
        error: self.error,
        // Call this method to initiate a sign-in
        signin() {
          self.userManager.getUser().then(user => {
            if (user) {
              self.user = user;
              self.error = null;
              self.forceUpdate();
            }
            else {
              self.userManager.signinRedirect({ state: window.location.href });
            }
          });
        }
      };
    }
    else {
      // 'user' is assigned, so the user is authenticated
      return {
        // The OAUTH access token recived after signing in that is used to make API calls
        accessToken: self.user.access_token,
        // The user profile (claims)
        profile: self.user.profile,
        // Call this method when an API call using the access token fails.
        // It will erase the access token and force the user to re-authenticate.
        removeUser: self.userManager.removeUser.bind(self.userManager),
        // Call this method to initiate a sign-out
        signout: self.userManager.signoutRedirect.bind(self.userManager),
      };
    }
  }

  render() {
    const self = this;

    return <BrowserRouter>
      <Switch>
        <Route exact path={self.settings.redirect_path} render={({ history }) => {
          if (self.error) {
            return self.props.children(self.getSession());
          }
          self.userManager.signinRedirectCallback().then(user => {
            // Received a user
            self.user = user;
            self.error = null;
            // Redirect to the original landing page
            history.push(user.state.substr(self.origin.length));
          }).catch(function (err) {
            console.log(err);
            // Save the error in state
            self.user = null;
            self.error = err;
            // Since 'user' and 'error' are not stored in 'state', we need to force an update here
            self.forceUpdate();
          });
          return <div></div>;
        }} />
        <Route exact path={self.settings.post_logout_redirect_path} render={() => {
          // The user is redirected to this route from the authentication server after signing out.
          // Redirect the user to some other location (usually an anonymous landing page, default is '/')
          window.location.href = self.settings.post_logout_path;
          return <div></div>;
        }} />
        <Route render={() => {
          if (!self.didInitialAttempt) {
            self.didInitialAttempt = true;
            self.userManager.getUser().then(user => {
              if (user) {
                self.user = user;
                self.error = null;
                self.forceUpdate();
              }
            });
          }
          return self.props.children(self.getSession());
        }} />
      </Switch>
    </BrowserRouter>;
  }
}

OidcConnect.defaultProps = {
  settings: {
    redirect_path: '/signin-oidc',
    post_logout_redirect_path: '/signout-callback-oidc',
    response_type: 'id_token token',
    post_logout_path: '/'
  }
};

OidcConnect.propTypes = {
  settings: propTypes.shape({
      /**
      * @property {string} settings.authority The URL of the OIDC/OAuth2 provider.
      */
      authority: propTypes.string.isRequired,
      /**
      * @property {string} settings.client_id  Your client application's identifier as registered with the OIDC/OAuth2 provider.
      */
      client_id: propTypes.string.isRequired,
      /**
      * @property {string} settings.redirect_uri (Optional) The path of your client application to receive a response from the OIDC/OAuth2 provider.
      */
      redirect_path: propTypes.string,
      /**
      * @property {string} settings.post_logout_redirect_uri (Optional) The path of your client application where it receives a callback after signing out from the OIDC/OAuth2 provider.
      */
      post_logout_redirect_path: propTypes.string,
      /**
      * @property {string} settings.response_type (Optional) The type of response desired from the OIDC/OAuth2 provider ( default: 'id_token').
      */
      response_type: propTypes.string,
      /**
      * @property {string} settings.scope The scope being requested from the OIDC/OAuth2 provider (default: 'openid').
      */
      scope: propTypes.string.isRequired,
      /**
      * @property {string} settings.post_logout_path (Optional) The path where the user is sent after signing out (default: '/').
      */
      post_logout_path: propTypes.string,
      /**
      * @property {string} settings.acr_values (Optional) The acr_values parameter sent when signing in.
      */
      acr_values: propTypes.string
  }).isRequired,
  /**
  * @property {func} children Raised when a user session has been established (or re-established), accepts one parameter 'session'.
  */
  children: propTypes.func.isRequired
};

export default OidcConnect;
