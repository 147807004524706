import React, {Component} from 'react';
import AppNav from '../../AppNav/AppNav';
import Instructions from '../../Instructions/Instructions'
import Wrapper from '../../Wrapper/Wrapper';
import SurveySteps from '../../SurveySteps/SurveySteps';
import Modal from './Modal/Modal';
import JournalObjectives from '../../ResultsList/JournalObjectives'
import * as Survey from 'survey-react';
import axios from 'axios'
import 'survey-react/survey.css';
import surveyObjectifs from '../../../surveys/surveyObjectifs'
import moment from 'moment';
import { connect } from 'react-redux'
import constants from '../../../constants'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { withAITracking } from 'react-appinsights';
import SmartContent from './Modal/SmartContent'

const form = "form";
const success = "success";
const results = "results";
const history = "history";

const journalSectionPath = ["/mon-journal-d-entrainement/objectifs-smart", "/mon-journal-d-entrainement/j-explore-je-choisis", "/mon-journal-d-entrainement/plan-d-entrainement"];

class Objectifs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      surveyComplete: false
    }

    this.onComplete = this.onComplete.bind(this);
  }

  componentWillMount() {
    Survey.Survey.cssType = "bootstrap";
    Survey.defaultBootstrapCss.navigationButton = "btn";
    Survey.defaultBootstrapCss.navigation.complete = "journalCompleteBtn";
    Survey.defaultBootstrapCss.navigation.prev = "journalPrevBtn";
    Survey.defaultBootstrapCss.navigation.next = "journalNextBtn";
  }

  componentDidMount() {
    // Getting accessToken from redux store
    let accessToken = this.props.userAuth.accessToken;

    if(this.props.objectifs.results === null){
      // Calling action to get all results
      this.props.getAllObjectifs(accessToken)
    }

  }

  onComplete(survey) {

    // Copying state of objectifs in new array
    let newData = [...this.props.objectifs.results];

    // Putting user's answers in array
    let questions = [survey.data.question1,survey.data.question2,survey.data.question3]
    let objectives = [];

    // Looping through users answers and pushing them in array if they are not empty
    for(var i=0; i<questions.length; i++){
      if(questions[i] !== undefined) {
        objectives.push(questions[i])
      }
    }

    // If there is one or more result in the store, we loop the results to get the last result
    if(newData.length >= 1) {
      for(var n = newData.length - 1; n >= newData.length - 1; n--) {
        // Transforming last item timestamp to date
        let lastItemDate = moment(newData[n].timestamp).format("LL");
        if(lastItemDate === moment().format('LL')) {
          // If last item date is the same as todays date, we update that data with the new data
          newData[n] = {
            timestamp: Date.now(),
            objectifs: objectives
          };
        } else {
          // If last item date is not the same as todays date, we push data to array with all other results
          newData.push(
            {
              timestamp: Date.now(),
              objectifs: objectives
            }
          )
        }
      }
    } else {
      // If there is no results, we create the first result
      newData.push(
        {
          timestamp: Date.now(),
          objectifs: objectives
        }
      )
    }

    // Creating new object to send in redux store
    let newState = {
      currentView: success,
      results: newData
    }

    // Getting accessToken and bonjour_person_id from redux store
    let accessToken = this.props.userAuth.accessToken;
    let bonjour_person_id = this.props.userAuth.profile.bonjour_person_id;

    // Calling action to create new objectif
    this.props.setNewObjectifs(newState, accessToken, bonjour_person_id)

    // Setting state.surveyComplete to true for loading purposes
    // this.setState({ surveyComplete: true })

  }

  objectifsSectionHandler(section) {

    var survey = new Survey.Model(surveyObjectifs);
    survey.showQuestionNumbers = "off";
    survey.requiredText = "";

    let view = null;

    // Getting results from state
    let allStateResults = this.props.objectifs.results;

    // Creating new array that will contain state results with new formating
    let newStateResults = [];

    // Looping through all state results
    for(var i=0; i<allStateResults.length; i++) {
      // Creating new array that will contain state objectives with new formating
      let newStateContent = []
      // Looping to add objectives of current result in the array
      for(var n=0; n<allStateResults[i].objectifs.length; n++){
        newStateContent.push(allStateResults[i].objectifs[n]);
      }
      // Adding date and objectives of current result in the array
      newStateResults.push(
        {
          date: moment(allStateResults[i].timestamp).format("LL"),
          objectifs: newStateContent
        }
      );
    }

    // Getting last item of array which is the latest entry
    let onlyCurrentRes = [newStateResults[newStateResults.length - 1]];

    // Reversing array items for display purposes
    let desAllStateResults = newStateResults.reverse();

    // Renders the history of the entries for the objectives
    if(section === history) {
      view = <Wrapper>
              <div className="box p-0" id="main">
                <div className="row">
                  <div className="col-9 my-auto">
                    <h2 className="surveySectionTitle d-inline px-2" id="focusTop" tabIndex="-1">Mes objectifs</h2>
                  </div>
                  <div className="col-3 text-right">
                    <button type="button" className="btn p-0" title="Directives objectifs SMART - fenêtre surgissante" data-toggle="modal" data-target="#objectifsSMART">
                      <span className="far fa-question-circle fa-2x p-1 purpleColor px-2"></span>
                    </button>
                  </div>
                </div>
              </div>
              <Instructions>
                <p>Quels seront les objectifs de mon plan personnel d’entraînement?</p>

                <p className="m-0">En t’inspirant des besoins prioritaires que tu as ciblés lors de l’analyse de tes résultats, formule ton ou tes objectifs SMART en suivant les directives. Pour lire les directives, clique sur l’icône <span aria-label="Directives objectifs SMART" className="far fa-question-circle purpleColor"></span> située à la droite de la rubrique Mes objectifs.</p>
              </Instructions>
              {desAllStateResults.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
              <div className="row">
                <div className="col-12 col-sm-6">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(results)}><i className="fas fa-chevron-left mr-2"></i> Retour à mes objectifs</button>
                </div>
              </div>
             </Wrapper>;
    } else if(section === results) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        // Renders the data if there are two or more entries in the database
        if(newStateResults.length >= 2){
          view = <Wrapper>
                  <div className="box p-0" id="main">
                    <div className="row">
                      <div className="col-9 my-auto">
                        <h2 className="surveySectionTitle d-inline px-2" id="focusTop" tabIndex="-1">Mes objectifs</h2>
                      </div>
                      <div className="col-3 text-right">
                        <button type="button" className="btn p-0" title="Directives objectifs SMART - fenêtre surgissante" data-toggle="modal" data-target="#objectifsSMART">
                          <span className="far fa-question-circle fa-2x p-1 purpleColor px-2"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <Instructions>

                    <p>Quels seront les objectifs de mon plan personnel d’entraînement?</p>

                    <p className="m-0">En t’inspirant des besoins prioritaires que tu as ciblés lors de l’analyse de tes résultats, formule ton ou tes objectifs SMART en suivant les directives. Pour lire les directives, clique sur l’icône <span aria-label="Directives objectifs SMART" className="far fa-question-circle purpleColor"></span> située à la droite de la rubrique Mes objectifs.</p>
                  </Instructions>
                  {onlyCurrentRes.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(history)}><i className="fas fa-history mr-2"></i> Consulter mon historique</button>
                    </div>
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(form)}><i className="fas fa-plus mr-2"></i> Créer de nouveaux objectifs</button>
                    </div>
                  </div>

                 </Wrapper>;
        // Renders the data if there is only one entry in the database
        } else {
          view = <Wrapper>
                  <div className="box p-0" id="main">
                    <div className="row">
                      <div className="col-9 my-auto">
                        <h2 className="surveySectionTitle d-inline px-2" id="focusTop" tabIndex="-1">Mes objectifs</h2>
                      </div>
                      <div className="col-3 text-right">
                        <button type="button" className="btn p-0" title="Directives objectifs SMART - fenêtre surgissante" data-toggle="modal" data-target="#objectifsSMART">
                          <span className="far fa-question-circle fa-2x p-1 purpleColor px-2"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <Instructions>
                    <p>Quels seront les objectifs de mon plan personnel d’entraînement?</p>

                    <p className="m-0">En t’inspirant des besoins prioritaires que tu as ciblés lors de l’analyse de tes résultats, formule ton ou tes objectifs SMART en suivant les directives. Pour lire les directives, clique sur l’icône <span aria-label="Directives objectifs SMART" className="far fa-question-circle purpleColor"></span> située à la droite de la rubrique Mes objectifs.</p>
                  </Instructions>
                  {onlyCurrentRes.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(form)}><i className="fas fa-plus mr-2"></i> Créer de nouveaux objectifs</button>
                    </div>
                  </div>
                 </Wrapper>;
        }
      // Triggers the form if there is no entries in the database
      } else {
        this.props.setObjectifsSection(form)
      }
    // Triggers the form if there is no entries in the database
    } else if(section === form) {
      view = <Wrapper>
                <div className="box py-2 px-0" id="main">
                  <div className="row">
                    <div className="col-12 my-auto">
                      <h2 className="surveySectionTitle d-inline px-2 py-4" id="focusTop" tabIndex="-1">Mes objectifs</h2>
                    </div>
                  </div>
                </div>
                <Instructions>
                  <p id="main">Quels seront les objectifs de mon plan personnel d’entraînement?</p>

                  <p className="m-0">En t’inspirant des besoins prioritaires que tu as ciblés lors de l’analyse de tes résultats, formule ton ou tes objectifs SMART en suivant les directives ci-dessous.</p>
                </Instructions>
                <SmartContent />
                <Survey.Survey model={survey} onComplete={this.onComplete} />
                {newStateResults.length ?
                <div className="col-12 col-sm-6 px-0">
                  <button className="btn btn-outline-purple px-3 mb-4 w-100" onClick={() => this.props.setObjectifsSection(results)}><i className="fas fa-times mr-2"></i> Annuler</button>
                </div> : ""}
              </Wrapper>;
    // Renders when the user successfully create a new entry in the database
    } else if (section === success){
      view = <Wrapper>
                  <div className="box my-3" id="main">
                    <p className="completedSurveyText m-0">Vos réponses ont été sauvegardées.</p>
                  </div>
                  <div className="col-12 text-center">
                    <button className="btn btn-outline-purple px-3 my-4" onClick={() => this.props.setObjectifsSection(results)}><i className="fas fa-stream"></i> Voir mes objectifs</button>
                  </div>
                </Wrapper>;
    }

    return view;

  }

  render() {

    return (
      <Wrapper>
        <AppNav section="Journal" auth={this.props.auth} />
        <main className="container">
          <SurveySteps section={1} path={journalSectionPath} />
          {this.props.objectifs.results !== null ? this.objectifsSectionHandler(this.props.objectifs.currentView) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
        <Modal type="objectifsSMART" />
      </Wrapper>
    )
  }

}

function mapStateToProps(state) {
  return {
    objectifs: state.objectifs,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNewObjectifs: (data, accessToken, bonjour_person_id) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "For-Student": bonjour_person_id,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'objectifs', data.results, {headers: headers}).then(res => {
        dispatch({ type: constants.SET_OBJECTIFS, payload: data })
      }).catch(function (error) {
      });
    },
    setObjectifsSection: (section) => {
      dispatch({ type: constants.SET_OBJECTIFS_SECTION, payload: section })
      if(document.getElementById("focusTop")) {
        document.getElementById("focusTop").focus();
      }
    },
    getAllObjectifs: (accessToken) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'objectifs',
      {
        headers:{
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {
        dispatch({ type: constants.GET_ALL_OBJECTIFS, payload: res.data })
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({type: constants.GET_ALL_OBJECTIFS, payload: []})
        }
      });
    }
  }
}


export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(Objectifs));
