import React, { Component } from 'react';
import Wrapper from '../Wrapper/Wrapper';
import AppNav from '../AppNav/AppNav';
import Instructions from '../Instructions/Instructions';
import { connect } from 'react-redux';
import constants from '../../constants';
import moment from 'moment';
import gif from '../../img/IQ5_Animation_Loading_Gris.gif';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import uuidv1 from 'uuid/v1';
import axios from 'axios';

class Portfolio extends Component {
  constructor(props){
    super(props)

    this.state={
      text: '',
      file: "Choisir un fichier",
      showEmojis: false,
      fileUri: []
    }
    this.deletePost = this.deletePost.bind(this)
    this.showEmojis = this.showEmojis.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.addEmoji = this.addEmoji.bind(this)
    this.publish = this.publish.bind(this)
  }

  // Code for adding emoji
  addEmoji(e){
      let sym = e.unified.split('-')
      let codesArray = []
      sym.forEach(el => codesArray.push('0x' + el))
      let emojiPic = String.fromCodePoint(...codesArray)
      this.setState({
        text: this.state.text + emojiPic
      })
    }

  // Opening emoji modal
  showEmojis(e) {
    this.setState({
      showEmojis: true
    }, () => document.addEventListener('click', this.closeMenu))
  }

  // Closing emoji modal
  closeMenu(e) {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState({
        showEmojis: false
      }, () => document.removeEventListener('click', this.closeMenu))
    }
  }

  // Publishing a post
  publish() {
    // Getting access token and deep copy of the portfolio state
    let accessToken = this.props.auth.accessToken;
    let serverPosts = JSON.parse(JSON.stringify(this.props.portfolio.posts));

    // Getting input data
    let text = document.getElementById('postText').value;
    let file = document.getElementById('fileUpload').files;
    let filePath = document.getElementById('fileUpload').value;

    // Check if theres a file in the input file
    if(!file.length) {
      // Renders message if there is no file and no text when publishing
      if(text === ""){
        alert("Vous devez écrire un mot ou joindre un fichier")
      } else {
        // Creates a state object
        let dataObject = {
          text: text,
          fileName: null,
          fileGuid: null,
          fileType: null,
          fileObject: null,
          timestamp: Date.now()
        }
        // Setting a null value for the post uri since no files are uploaded
        let postUri = JSON.parse(JSON.stringify(this.state.fileUri));
        postUri.push("null")
        this.setState({ fileUri: postUri })
        // Pushing the new post to the copy of the store
        serverPosts.push(dataObject)
        this.props.createNewPost(serverPosts, accessToken)
        // Setting text input and file input to their default values
        this.setState({text: ""});
        this.setState({file: "Choisir un fichier"});
      }
    } else {

      // Returns only the filename from the file path
      var filename = filePath.replace(/^.*?([^\\/]*)$/, '$1');

      // Returns appropriate class for the icon next to the file (if the file is a .png the icon will be an image icon)
      let fileType = this.checkFileType(filePath)

      // Generates a GUID -> Will be used as file name
      let guid = uuidv1();

      // Creates new file from file and gives it the GUID as a name
      const newFile = new File([file[0]], guid, {type: file[0].type});

      // Creates and appends the file to a FormData object
      let formData = new FormData();
      formData.append('file', newFile);

      // Declaring headers
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'multipart/form-data'
      }
      // Uploading file to storage api
      axios.post(process.env.REACT_APP_STORAGEAPI+'portfolio/', formData, {headers: headers}).then(res => {
        // Returns only the file object name on the server
        let location = res.headers.location.replace("portfolio/","")
        serverPosts.push({
          text: text,
          fileName: filename,
          fileGuid: guid,
          fileType: fileType,
          fileObject: location,
          timestamp: Date.now()
        })

        this.props.createNewPost(serverPosts, accessToken)
        // Getting uri of the uploaded file
        axios.get(process.env.REACT_APP_STORAGEAPI+'portfolio/'+location, {headers:{Authorization: 'Bearer ' + accessToken}}).then(response => {
          // Pushing the new post to the copy of the state
          let postUri = JSON.parse(JSON.stringify(this.state.fileUri));

          postUri.push(response.data.uri)
          this.setState({ fileUri: postUri })

        }).catch(function (error) {
        })

        // Setting text input and file input to their default values
        this.setState({text: ""});
        this.setState({file: "Choisir un fichier"});
      }).catch(function (error) {
      });

    }
  }

  deletePost(e) {
     // Getting specific post to delete
     let targetIndex = e.target.id;
     let post = document.getElementById(targetIndex)
     let index = post.getAttribute("index")
     let file = post.getAttribute("file")

     // Getting deep copies of store and state
     let allPosts = JSON.parse(JSON.stringify(this.props.portfolio.posts));
     let fileUris = JSON.parse(JSON.stringify(this.state.fileUri));
     let accessToken = this.props.auth.accessToken;

     // Remove file from store and state
     allPosts.splice(index, 1)
     fileUris.splice(index, 1)

     // Setting new state
     this.setState({ fileUri: fileUris })

     // Checks if post has a file
     if(file === null) {
       // If there is no file, deletes the post from the database (text)
       this.props.deletePost(allPosts, accessToken)
     } else {
       let headers = {
         Authorization: 'Bearer ' + accessToken
       }
       // If there is a file, deletes the post from the database (text and file)
       axios.delete(process.env.REACT_APP_STORAGEAPI+'portfolio/'+file, {headers: headers}).then(res => {
         this.props.deletePost(allPosts, accessToken)
       }).catch(function (error) {
       });
     }

  }

  checkFileType(file) {
    // Setting different file types
    const img = ["jpeg","jpg","png","tiff","gif","bmp","webp","svg"];
    const video = ["mp4","m4a","m4v","f4v","f4a","m4b","m4r","f4b","mov","3gp","3gp2","3g2","3gpp","3gpp2","ogg","oga","ogv","ogx","wmv","wma","webm","flv"];
    const word = ["doc","docx","docm"];
    const pdf = ["pdf"]

    // Returns file's filetype
    let type = file.split('.').pop();

    // Returns the proper icon for the type of file
    if(img.includes(type)) {
      return "far fa-file-image"
    } else if(video.includes(type)) {
      return "far fa-file-video"
    } else if(word.includes(type)) {
      return "far fa-file-word"
    } else if(pdf.includes(type)) {
      return "far fa-file-pdf"
    } else {
      return "far fa-file"
    }

  }

  setFileInputName(e){

    // Gets full path of file
    let filePath = document.getElementById('fileUpload').value;
    // Returns only the file name + extension
    var filename = filePath.replace(/^.*?([^\\/]*)$/, '$1');
    // Sets the input file label as the file name
    this.setState({file: filename});
  }

  componentDidMount() {
    let accessToken = this.props.auth.accessToken;

    // Check whether there is posts or not in the store
    if(this.props.portfolio.posts === null){

      // Since there is no posts we get the posts from the database (text only)
      // https://app-storage-dev.cforp.io/api/v1/store/portfolio -> Storing text
      // https://app-storage-dev.cforp.io/api/v1/store/portfolio/{postId} -> Storing file
      axios.get(process.env.REACT_APP_STORAGEAPI+'portfolio', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {

        // Storing text of posts in the redux store
        let posts = res.data;
        this.props.setPosts(posts)

        let promises = [];
        let fileLink = [];

        // Preparing request in an array
        for (let i = 0; i < posts.length; i++) {
            promises.push(axios.get(process.env.REACT_APP_STORAGEAPI+'portfolio/'+posts[i].fileObject, { headers: {Authorization: 'Bearer ' + accessToken} }));
        }

        // Getting the file uri of each post and storing it in the component state
        // Uri is valid for 8 hours
        axios.all(promises)
            .then(axios.spread((...args) => {
                for (let i = 0; i < args.length; i++) {
                    fileLink.push(args[i].data.uri)
                }
                this.setState({ fileUri: fileLink })
            }))

      }).catch((error) => {
        // Setting posts as an empty array when no posts are found
        if(error.response.status === 404) {
          this.props.setPosts([]);
        }
      });


    }

  }

  render() {
    // Setting a copy of the store (copy of the posts)
    let allPosts = JSON.parse(JSON.stringify(this.props.portfolio.posts))

    let posts = null;

    // Creating new properties index and fileUri in the copy of the store
    if(allPosts !== null) {
      for(var n=0; n<allPosts.length; n++) {
        allPosts[n]["index"] = n;
        allPosts[n]["fileUri"] = this.state.fileUri[n]
      }
      // Reversing the posts to get the latest post first when there is more than one post
      if(allPosts.length >= 1) {
        posts = allPosts.reverse();
      } else {
        posts = []
      }
    }

    // Renders page content
    return(
      <Wrapper>
        <AppNav section="portfolio" auth={this.props.auth} />
        <div className="container" id="main">
          <Instructions>
            <p>Mes idées<br />
            Ce que je ressens</p>
            <p>Cet espace t’appartient. Tu n’as pas à montrer ce que tu écris à ton enseignante ou à ton enseignant, à d’autres élèves ou à qui que ce soit. Mais, si tu en ressens le besoin et que c’est ton souhait, tu peux montrer ton journal et tes idées à une ou à plusieurs de ces personnes.</p>
            <p>Écris tes impressions au sujet de ton plan personnel d’entraînement.</p>
            <ul>
              <li>Ton plan personnel d’entraînement répond-il à tes besoins jusqu’à présent?</li>
              <li>De quoi es-tu la plus fière ou le plus fier jusqu’à maintenant?</li>
              <li>Qu’est-ce qui t’encourage ou te décourage le plus?</li>
              <li>Qu’est-ce qui te motiverait davantage?</li>
              <li>Tes besoins ont-ils changé? Si oui, lesquels et pourquoi?</li>
              <li>Que souhaites-tu modifier? (p. ex., la durée, l’intensité, le type d’activité, tes choix d’aliments). Pourquoi?</li>
              <li>Quelle habitude contribue à tes progrès? Laquelle nuit à tes progrès?</li>
            </ul>
            <p>Ces questions ne sont que des idées pour susciter ta réflexion. Tu peux choisir d’écrire ce que tu veux. Tu peux aussi ajouter des fichiers de texte ou d'image et même des emojis.</p>
            <p>Bonne réflexion!</p>
          </Instructions>
          <div className="box py-4 mb-2">
            <form>

              {/* Renders post form - User can insert text, emojis and file */}
              <div className="row">
                <div className="col-12 px-4">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Nouvelle publication</label>
                    <textarea className="form-control" id="postText" rows="3" value={this.state.text} onChange={(e) => this.setState({text: e.target.value})}></textarea>
                    {
                      this.state.showEmojis ?
                      <span ref={el => (this.emojiPicker = el)}>
                        <Picker size="24" onSelect={this.addEmoji} i18n={{ search: 'Recherche', categories: {
                          search: 'Résultats de recherche',
                          recent: 'Récents',
                          people: 'Binettes et personnes',
                          nature: 'Animaux et nature',
                          foods: 'Nourriture et boisson',
                          activity: 'Activités',
                          places: 'Voyages et lieux',
                          objects: 'Objets',
                          symbols: 'Symboles',
                          flags: 'Drapeaux'
                        } }} />
                      </span>
                      :
                        ""
                    }
                    <span onClick={this.showEmojis} className="emojiBtn" aria-hidden="true" >
                      {String.fromCodePoint(0x1f60a)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 px-4 mb-3">
                  <div className="custom-file">
                    <input type="file" className="custom-file-input" id="fileUpload" onChange={(e) => this.setFileInputName(e)} />
                    <label className="custom-file-label" htmlFor="fileUpload">{this.state.file}</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6"></div>
                <div className="col-6 px-4">
                  <button type="button" onClick={() => this.publish()} className="btn btn-outline-purple w-100">Publier</button>
                </div>
              </div>

            </form>
          </div>

          {/* Renders posts when both the text and files are retrieved from the database */}
          {allPosts !== null && this.state.fileUri !== [] ? posts.map((item,index) =>
            <div className="row my-4" key={index} id={`post${index}`}>
              <div className="col-12">
                <p>{moment(item.timestamp).format("LL")} à {moment(item.timestamp).format('HH:mm')}</p>
                <div className="box p-3">
                  <div className="row">
                      <div className="col-10">
                        {item.text !== "" ? item.fileName !== null ? <p className="mb-4" aria-labelledby={`Commentaire ${index+1} - ${item.text}`}>{item.text}</p> : <p className="m-0" aria-labelledby={`Commentaire ${index+1} - ${item.text}`}>{item.text}</p> : ""}
                        {item.fileName !== null ? <p className="m-0"><i className={`mr-2 ${item.fileType}`}></i><a href={item.fileUri} target="_blank" rel="noopener noreferrer">{item.fileName}</a></p> : ""}
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-end">
                        <button aria-label="Supprimer le commentaire" className="fas fa-trash" id={`indexPost${item.index}`} index={item.index} file={item.fileObject} onClick={(e) => {
                          if(window.confirm('Supprimer le commentaire?')){this.deletePost(e)};
                        }}></button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}

        </div>
      </Wrapper>
    )
  }
}

function mapStateToProps(state){
  return {
    auth: state.userAuth,
    portfolio: state.portfolio
  }
}

function mapDispatchToProps(dispatch){
  return {
    createNewPost: (serverPost, accessToken) => {
      document.getElementById('postText').value = "";
      document.getElementById('fileUpload').value = "";
      // Declaring headers
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      }
      // Uploading file to storage api
      axios.put(process.env.REACT_APP_STORAGEAPI+'portfolio', serverPost, {headers: headers}).then(res => {
        dispatch({ type: constants.CREATE_PORTFOLIO_POST, payload: serverPost })
      }).catch(function (error) {
      });
    },
    setPosts: (res) => {
      dispatch({type: constants.SET_PORTFOLIO_POSTS, payload: res})
    },
    deletePost: (posts, accessToken) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'portfolio', posts, {headers: headers}).then(res => {
        dispatch({ type: constants.DELETE_POST, payload: posts })
      }).catch(function (error) {
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio)
