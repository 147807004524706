import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const SurveySteps = (props) => {
  // Renders the navbar in the plan d'entrainement page
  return (
    <div className="row my-3">
      <div className="col-6 col-md-4 pr-1 pr-md-3 mb-2">
        <Link className={`btn btn-outline-purple fontSize500 ${(props.section === 1) ? "active" : ""} px-0 w-100 h-100`} to={props.path[0]}>Mes objectifs</Link>
      </div>
      <div className="col-6 col-md-4 pl-1 pl-md-3 pr-md-3 mb-2">
        <Link className={`btn btn-outline-purple fontSize500 ${(props.section === 2) ? "active" : ""} px-0 w-100 h-100`} to={props.path[1]}>Mes choix</Link>
      </div>
      <div className="col-6 col-md-4 pr-1 pl-md-3 pr-md-3 mb-2">
        <Link className={`btn btn-outline-purple fontSize500 ${(props.section === 3) ? "active" : ""} px-0 w-100 h-100`} to={props.path[2]}>Mon plan d’entraînement</Link>
      </div>
    </div>
  )
}

SurveySteps.propTypes = {
  section: PropTypes.number.isRequired,
  path: PropTypes.array.isRequired,
}

export default withAITracking(SurveySteps);
