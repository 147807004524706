import React from 'react';
import PropTypes from 'prop-types';
import {Radar} from 'react-chartjs-2';
import Wrapper from '../Wrapper/Wrapper'
import { withAITracking } from 'react-appinsights';

const ResultsList = (props) => {

  // Setting dataset for one or no result
  const data = {
    labels: ["A", "B", "C"],
    datasets: [
      {
        label: 'Mon résultat',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: props.results ? props.results.data : [0,0,0]
      }
    ]
  };

  // Setting our option. We can have a minimum of 1 point and a maximum of 35 points per category
  var options = {
    scale: {
      ticks: {
        beginAtZero: true,
        min: 1,
        max: 35
      }
    }
  };


  return (
    <div className="my-4">
        {/* Checking if the props checkbox is empty or not */}
        {(props.checkbox === "") ?
          // If empty return date
          <p className="text-right mb-2 fontSize500">{props.results.date}</p>
          :
          // If not empty return checkbox with date information
          <div className="custom-control custom-checkbox mr-sm-2 mb-2 text-right">
            <input type="checkbox" className="custom-control-input checkboxDate" id={props.results.date} name={props.results.date} onChange={props.checked} />
            <label className="custom-control-label fontSize500" htmlFor={props.results.date}>{props.results.date}</label>
          </div>
        }
        {/* Renders collapsible element for current result */}
        <div className="accordion" id={`accordionExample${props.index}`}>
          <div className="card mb-2">
            <div className="card-header p-0" id={`headingOne${props.index}`}>
              <button className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapseOne${props.index}`} aria-expanded="false" aria-controls={`collapseOne${props.index}`}>
                <p className="m-0 float-left">Ma condition physique</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>

            <div id={`collapseOne${props.index}`} className={`collapse ${(props.collapse) ? props.collapse : ""}`} aria-labelledby={`headingOne${props.index}`} data-parent={`#accordionExample${props.index}`}>
              <div className="card-body pt-0 pb-3 px-3">
                {props.results.conditions}
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header p-0" id={`headingTwo${props.index}`}>
              <button className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapseTwo${props.index}`}  aria-expanded="false" aria-controls={`collapseTwo${props.index}`}>
                <p className="m-0 float-left">Mes habitudes saines</p>
                <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id={`collapseTwo${props.index}`} className={`collapse ${(props.collapse) ? props.collapse : ""}`} aria-labelledby={`headingTwo${props.index}`} data-parent={`#accordionExample${props.index}`}>
              <div className="card-body pt-0 pb-3 px-3">
                {props.results.habitudes}
              </div>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header p-0" id={`headingThree${props.index}`}>
              <button className="btn btn-link collapsed w-100 mb-0 p-2 px-3" data-toggle="collapse" data-target={`#collapseThree${props.index}`} aria-expanded="false" aria-controls={`collapseThree${props.index}`}>
                  <p className="m-0 float-left">Mes expériences et mon attitude</p>
                  <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
              </button>
            </div>
            <div id={`collapseThree${props.index}`} className={`collapse ${(props.collapse) ? props.collapse : ""}`} aria-labelledby={`headingThree${props.index}`} data-parent={`#accordionExample${props.index}`}>
              <div className="card-body pt-0 pb-3 px-3">
                {props.results.experiences}
              </div>
            </div>
          </div>
        </div>
        {(props.radar === "show")
        // If radar props is equal to show, we render the chart and legend
        ?
        <Wrapper>
          <div className="row">
            <div className="col-12">
              <div className="box p-3 mb-2" aria-hidden="true">
              <p><strong>Légende</strong></p>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <p>A : Ma condition physique</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <p>B : Mes habitudes saines</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <p>C : Mes expériences et mon attitude</p>
                  </div>
                  <div className="col-12">
                    <p>L’échelle de 5 à 35 présente le pointage obtenu pour chacun des critères de ton diagnostic. Un pointage élevé indique que ta condition physique, tes habitudes, ton expérience ou ton attitude sont saines. Un pointage bas te donne une idée de ce que tu pourrais améliorer.</p>
                    <p>En survolant chacun des sommets du triangle, tu verras apparaître ton pointage exact pour chacun des critères.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <Radar data={data} options={options} />
            </div>
          </div>
        </Wrapper>
        :
        ""}
    </div>
  )
}

ResultsList.propTypes = {
  results: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  collapse: PropTypes.string,
  radar: PropTypes.string,
  checkbox: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])
}

export default withAITracking(ResultsList);
