import React from 'react';
import PropTypes from 'prop-types';
import ModalContentObjectifs from './ModalContentObjectifs';
import ModalContentPlan from './ModalContentPlan';
import { withAITracking } from 'react-appinsights';

const Modal = (props) => {
  let modalContent = null;
  // Checking the string received from props and renders component accordingly
  if(props.type === "objectifsSMART") {
    modalContent = <ModalContentObjectifs />
  } else {
    modalContent = <ModalContentPlan />
  }
  return (
    <div className="modal fade" id={props.type} tabIndex="-1" role="dialog" aria-labelledby={props.type} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            {modalContent}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  type: PropTypes.string.isRequired
}

export default withAITracking(Modal);
