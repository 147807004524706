import React, {Component} from 'react';
import AppNav from '../../AppNav/AppNav';
import Instructions from '../../Instructions/Instructions'
import Wrapper from '../../Wrapper/Wrapper';
import SurveySteps from '../../SurveySteps/SurveySteps';
import Modal from './Modal/Modal';
import 'survey-react/survey.css';
import { connect } from 'react-redux'
import constants from '../../../constants'
import { withAITracking } from 'react-appinsights';
import PlanJournal from './PlanJournal'
import axios from 'axios'
import PlanHistory from './PlanHistory'
import PlanCreate from './PlanCreate'
import moment from 'moment'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'

const journalSectionTitle = ["Objectifs SMART", "J'explore, je choisis", "Plan d'entraînement"];
const journalSectionPath = ["/mon-journal-d-entrainement/objectifs-smart", "/mon-journal-d-entrainement/j-explore-je-choisis", "/mon-journal-d-entrainement/plan-d-entrainement"];

const results = "results";
const history = "history";
const calendar = "calendar";

class Plan extends Component {

  constructor(props){
    super(props)

    this.state = {
      currentView: "results", // form, results, history
      activeWeek: 1,
      results: []
    }
    this.sendData = this.sendData.bind(this)
    this.changePlanView = this.changePlanView.bind(this)
  }

  createEmptyData() {
    // Creating empty results
    let emptyResults = [];
    let weekObj = {}

    let weekDays = ["jour1","jour2","jour3","jour4","jour5","jour6","jour7"]
    // Looping through 6 weeks
    for(var i=0; i<6; i++) {
      // Looping through every day of each weeks
      for(var j=0; j<7; j++) {
        // Adds an object for each day (jour1, jour2, etc.) to the weekObj object
        weekObj[weekDays[j]] = {
          text: "",
          checked: false,
          date: null
        }
      }
      // Adds a comment property for each week
      weekObj["commentaires"] = []
      // Pushing current week to the emptyResults array
      emptyResults.push(weekObj)
    }

    return emptyResults;
  }

  componentDidMount() {
    let accessToken = this.props.auth.accessToken;

    if(this.props.plan.results === null) {
      // Getting Plan results
      axios.get(process.env.REACT_APP_STORAGEAPI+'journal', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {
        this.props.getPlanResults(res.data);
      }).catch((error) => {
        if(error.response.status === 404) {
          this.props.getPlanResults([]);
          this.changePlanView(calendar)
        }
      });
    }


  }

  createEmptyPlan() {
    //Creating empty results
    let allResults = [];

    let emptyResults = this.createEmptyData()

    // Creates an empty plan with the empty results
    allResults.push({
      timestamp: Date.now(),
      data: emptyResults,
      week: 1,
      saved: null,
      dates: null
    });


    this.props.setEmptyPlan(allResults)
  }

  sendData(){
    // User info
    let accessToken = this.props.auth.accessToken;
    let bonjour_person_id = this.props.auth.profile.bonjour_person_id;

    // Deep copy of results
    let results = JSON.parse(JSON.stringify(this.props.plan.results));
    // last results
    let currentRes = [];

    // Getting last results in variable
    for(var i=results.length-1; i>=results.length-1; i--) {
      currentRes = results[i]
    }

    // Setting saved property in last results
    currentRes.saved = Date.now();

    // pushing back our last result in the deep copy of all results
    for(var n=results.length-1; n>=results.length-1; n--) {
      results[n] = currentRes;
    }

    // Sending data to database
    this.props.loading(true);
    this.props.sendData(accessToken, bonjour_person_id, results, false);
  }

  changePlanView(section) {
    this.props.changePlanView(section)
  }

  createNewPlan() {

    // User info
    let accessToken = this.props.auth.accessToken;
    let bonjour_person_id = this.props.auth.profile.bonjour_person_id;

    // Deep copy of results
    let results = JSON.parse(JSON.stringify(this.props.plan.results));

    // Deep copy of newPlan
    let newPlan = JSON.parse(JSON.stringify(this.props.plan.newPlan));

    let lastRes = null;
    let lastDate = null;

    if(this.props.plan.results.length >= 1) {
      // Getting date of last plan in results
      lastRes = results[results.length - 1];
      lastDate = moment(lastRes.timestamp).format('LL');
    }


    // Today's date formated
    let today = moment().format('LL');


    if(lastDate === today) {
      // Re-init. the data for today plan. (Can only create one plan per day)
      lastRes.timestamp = Date.now();
      lastRes.data = newPlan.data;
      lastRes.week = newPlan.week;
      lastRes.saved = newPlan.saved;
      lastRes.dates = newPlan.dates;

      for(var n=results.length-1; n>=results.length-1; n--) {
        results[n] = lastRes;
      }

    } else {
      // Pushing new plan to results
      results.push(newPlan);
    }

    // Calling action to create the new plan
    this.props.createNewPlan(accessToken, bonjour_person_id, results)
  }

  copyPlan(e) {
    let element = document.getElementById(e.target.id)
    let newPlanRes = JSON.parse(JSON.stringify(this.props.plan.newPlan));
    let results = JSON.parse(JSON.stringify(this.props.plan.results));

    // Getting the last plan
    let lastResult = results[results.length-1]

    // Check whether button is active
    if(element.classList.contains("active")) {
      // Looping through each week and setting the new plan results as the last plan's results
      for(var n=0; n<newPlanRes.data.length; n++) {
        newPlanRes.data[n].jour1.text = lastResult.data[n].jour1.text
        newPlanRes.data[n].jour2.text = lastResult.data[n].jour2.text
        newPlanRes.data[n].jour3.text = lastResult.data[n].jour3.text
        newPlanRes.data[n].jour4.text = lastResult.data[n].jour4.text
        newPlanRes.data[n].jour5.text = lastResult.data[n].jour5.text
        newPlanRes.data[n].jour6.text = lastResult.data[n].jour6.text
        newPlanRes.data[n].jour7.text = lastResult.data[n].jour7.text
      }
    // If the button is not active, we're setting each day as an empty string
    } else {
      for(var m=0; m<newPlanRes.data.length; m++) {
        newPlanRes.data[m].jour1.text = ""
        newPlanRes.data[m].jour2.text = ""
        newPlanRes.data[m].jour3.text = ""
        newPlanRes.data[m].jour4.text = ""
        newPlanRes.data[m].jour5.text = ""
        newPlanRes.data[m].jour6.text = ""
        newPlanRes.data[m].jour7.text = ""
      }
    }

    // Saving data in the store
    this.props.setNewPlan(newPlanRes)

  }

  loadSection(){
    let view = null;
    let stateResults = null;

    if(this.props.plan.results.length >= 1) {
      // If there is at least one plan we copy the plans
      stateResults = JSON.parse(JSON.stringify(this.props.plan.results));

      // Removes the last plan which is the current plan
      stateResults.pop();

      // Adds a planNumber to each plan
      for(var i=0; i<stateResults.length; i++){
        stateResults[i]["planNumber"] = i+1
      }

      // Reversing plans for the history page. Shows the last plans first
      stateResults.reverse();

    }

    // Shows the current plan
    if(this.props.plan.currentView === results && stateResults !== null) {
      view = <Wrapper>
              <Instructions>
                <p>Crée ton plan, en suivant ces étapes.</p>
                <ol>
                  <li>Détermine la fréquence et le jour auxquels tu travailleras sur chacune des composantes de ta condition physique ainsi qu'à l'atteinte de tes objectifs SMART liés à tes habitudes saines, et à tes expériences et tes attitudes (p. ex., échauffement à tous les jours, endurance cardiorespiratoire cinq fois par semaine, c’est-à-dire le mardi, le mercredi, le jeudi, le samedi et le dimanche). Notes ces données dans ton journal d’entraînement.</li>
                  <li>En te basant sur ta recherche, choisis les activités, les exercices ou les actions que tu incluras dans ton plan d'action pour chacune des composantes. Clique sur l’icône <span className="far fa-question-circle purpleColor"></span> pour lire les consignes qui t’aideront à décrire chaque composante.</li>
                </ol>
                <p>Une fois ton journal d’entraînement rempli, tu es prête ou prêt à passer à l’action! Il est important de garder en tête ta sécurité et celle des autres lors de tes entraînements. Révise les règles de sécurité propres aux différents locaux où tu t’entraînes. Une once de prévention vaut une livre de guérison.</p>
                <p>Sers-toi de ton journal d’entraînement pour monitorer tes actions. Chaque jour, coche la case à la droite si tu as accompli toutes les activités planifiées. Lorsque tu réfléchis à tes réussites et à tes embûches, tu peux prendre des décisions visant à ajuster ton plan personnel d’entraînement et à améliorer ta santé globale. En temps opportun, remplis les fiches énumérées ci-dessous pour t’aider dans ta réflexion :</p>
                <ul>
                  <li>Retour sur ma semaine;</li>
                  <li>Un coup d’œil sur mon cheminement.</li>
                </ul>
                <p className="m-0">Tu peux également écrire tes réflexions dans la section « Notes personnelles ».</p>

              </Instructions>
              <PlanJournal save={this.sendData} new={() => this.changePlanView(calendar)} history={() => this.changePlanView(history)} />
            </Wrapper>
    // Shows the history of plans
    } else if(this.props.plan.currentView === history && stateResults !== null) {
      view = <Wrapper>
              {stateResults.map((item,index) => <PlanHistory results={item} key={index} index={index} />)}
              <div className="col-12 col-sm-6 px-0">
                <button className="btn btn-outline-purple px-3 mb-4 w-100" onClick={() => this.changePlanView(results)}><i className="fas fa-chevron-left mr-2"></i> Revenir à mes activités</button>
              </div>
            </Wrapper>
    // Page to create a new plan
    } else if(this.props.plan.currentView === calendar) {
      view = <Wrapper>
              <Instructions>
                <p className="m-0">Quand commenceras-tu ton entraînement? Clique sur cette date. La date de la fin de ton programme s’affichera automatiquement. De plus, toutes les dates de la durée de ton programme d’entraînement s’afficheront automatiquement dans ton plan spécifique.</p>
              </Instructions>
              <div className="box my-4">
                <PlanCreate />
                <div className="row text-center">

                  <div className="col-12">

                      {this.props.plan.newPlan === undefined ?
                      <p>Vous devez choisir une date de début ou une date de fin.</p> :
                      <p>Du {moment(this.props.plan.newPlan.dates[0]).format('LL')} au {moment(this.props.plan.newPlan.dates[1]).format('LL')}</p>}

                  </div>

                  <div className="col-12 my-3">
                    {this.props.plan.results.length >= 1 ?
                      <button type="button" className="btn btn-outline-purple" data-toggle="button" aria-pressed="false" autoComplete="off" id="copyPlan" onClick={(e) =>   this.copyPlan(e)}>
                        <i className="fas fa-copy mr-2"></i> Copier les données de mon dernier plan d'entrainement
                      </button>
                    : ""}
                  </div>

                  <div className="col-12">
                    <button className="btn btn-outline-purple px-3 mb-4 mr-2" onClick={() => this.createNewPlan()}><i className="fas fa-plus mr-2"></i> Créer mon plan d'entrainement</button>
                    {this.props.plan.results.length >= 1 ?
                    <button className="btn btn-outline-purple px-3 mb-4" onClick={() => this.changePlanView(results)}><i className="fas fa-times mr-2"></i> Annuler</button> :
                    ""}
                  </div>
                </div>
              </div>
            </Wrapper>
    }

    return view;
  }

  render() {

    return (
      <Wrapper>
        <AppNav section="Journal" auth={this.props.auth} />
        <main className="container">
          <SurveySteps title={journalSectionTitle} section={3} path={journalSectionPath} />
          <div className="box p-0" id="main">
            <div className="row">
              <div className="col-9 my-auto">
                <h2 className="surveySectionTitle d-inline px-2" tabIndex="-1" id="focusTop">Je planifie mon horaire</h2>
              </div>
              <div className="col-3 text-right">
                <button type="button" className="btn p-0" title="Consignes du plan d'entraînement - fenêtre surgissante" data-toggle="modal" data-target="#planHoraire">
                  <span className="far fa-question-circle fa-2x p-1 purpleColor px-2"></span>
                </button>
              </div>
            </div>
          </div>
          {this.props.plan.results !== null ? this.loadSection() : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
        <Modal type="planHoraire" />
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    plan: state.plan,
    auth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPlanResults: (res) => {
      dispatch({ type: constants.SET_PLAN_DATA, payload: res })
    },
    sendData: (accessToken, bonjour_person_id, results, loading) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "For-Student": bonjour_person_id,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'journal', results, {headers: headers}).then(res => {
        dispatch({ type: constants.SET_PLAN_DATA, payload: results, loading: loading })
      }).catch(function (error) {
      });
    },
    changePlanView: (section) => {
      dispatch({ type: constants.CHANGE_PLAN_SECTION, payload: section })
      if(document.getElementById("focusTop")) {
        document.getElementById("focusTop").focus();
      }
    },
    setEmptyPlan: (data) => {
      dispatch({ type: constants.SET_EMPTY_PLAN, payload: data })
    },
    createNewPlan: (accessToken, bonjour_person_id, results) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "For-Student": bonjour_person_id,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'journal', results, {headers: headers}).then(res => {
        dispatch({ type: constants.CREATE_NEW_PLAN, payload: results })
      }).catch(function (error) {
      });
    },
    loading: (res) => {
      dispatch({ type: constants.PLAN_LOADING, payload: res })
    },
    updateData: (results) => {
      dispatch({ type: constants.SET_PLAN_DATA, payload: results })
    },
    setNewPlan: (results) => {
      dispatch({ type: constants.SET_NEW_PLAN, payload: results })
    }
  }
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(Plan));
