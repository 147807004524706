import React from 'react';
import PropTypes from 'prop-types';
import {Radar} from 'react-chartjs-2';
import Wrapper from '../Wrapper/Wrapper';
import ResultsList from './ResultsList';
import { withAITracking } from 'react-appinsights';

const History = (props) => {

    // Data reveived from props
    let results = props.data;
    let dates = props.dates;
    let datasetsValues = [];
    let resultList = [];

    // Mapping results to create chart with results according to selected dates
    results.map((item, index) => {
        // Maximum of 3 results to view at a time so we put 3 colors in an array
        var colors = ['rgba(0, 191, 255, 1)','rgba(128, 0, 255, 1)','rgba(255, 128, 0, 1)']
        var colorsT = ['rgba(0, 191, 255,0.2)','rgba(128, 0, 255,0.2)','rgba(255, 128, 0, 0.2)']
        // Looping through dates
        for (var i = 0; i < dates.length; i++) {
            // Checking if the prop date received is matching the date of the current item in map
            if(dates[i] === item.date) {
                // If we have a match we push the information in the datasetvalue array (color of the date and text in the chart, data and label, etc.)
                datasetsValues.push({
                    label: item.date,
                    backgroundColor: colorsT[i],
                    borderColor: colors[i],
                    pointBackgroundColor: colors[i],
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: colors[i],
                    data: item.data
                })
                // We push the results component with the selected date information in an array
                resultList.push(<ResultsList results={item} index={index} key={index} checkbox="" />);
            }
        }
        return datasetsValues;
    })

    // Specifying data information (Name of labels and the datasets with created)
    const data = {
      labels: ["A", "B", "C"],
      datasets: datasetsValues
    };

    // Setting our option. We can have a minimum of 1 point and a maximum of 35 points per category
    var options = {
      scale: {
        ticks: {
          beginAtZero: true,
          min: 1,
          max: 35
        }
      }
    };

    return(
        <Wrapper>
            <Radar data={data} options={options} />
            {/* Mapping our result component array to display all the selected dates results */}
            {resultList.map(result => result)}
        </Wrapper>
    )
}

History.propTypes = {
  data: PropTypes.array.isRequired,
  dates: PropTypes.array.isRequired
}

export default withAITracking(History);
