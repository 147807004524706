import React, { Component } from 'react';
import './DateLangFr';
import OidcConnect from './OidcConnect';
import "./styles.css";
import WhoAmI from './WhoAmI';
import store from './store'
import { Provider } from 'react-redux'
import { withAITracking } from 'react-appinsights';

// Oidc Settings
const OidcSettings = {
    authority: process.env.REACT_APP_BONJOURAPI,
    client_id: process.env.REACT_APP_BONJOURCLIENTID,
    scope: process.env.REACT_APP_BONJOURSCOPE
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { userProfile: undefined };
  }


  render() {

    // Authentication
    // Allways shows the bonjour api login page
    return (
      <Provider store={store}>
        <OidcConnect settings={OidcSettings}>{ session =>
          session.error ?
            <h1 style={{color: 'red'}}>{''+session.error}</h1> :
              (session.profile ?
                <WhoAmI auth={session} /> :
                  <div>
                    {session.signin()}
                  </div>)
          }
        </OidcConnect>
      </Provider>
    );
  }
}


export default withAITracking(App);
