export default {
    SET_WHOAMI: 'SET_WHOAMI',
    SET_USERAUTH: 'SET_USERAUTH',
    CREATE_DIAGNOSTIQUE: 'CREATE_DIAGNOSTIQUE',
    RESET_DIAGNOSTIQUE: 'RESET_DIAGNOSTIQUE',
    SET_OBJECTIFS: 'SET_OBJECTIFS',
    SET_OBJECTIFS_SECTION: 'SET_OBJECTIFS_SECTION',
    SET_ACTIVITIES: 'SET_ACTIVITIES',
    SET_ACTIVITIES_SECTION: 'SET_ACTIVITIES_SECTION',
    GET_RESULTS: 'GET_RESULTS',
    GET_ALL_OBJECTIFS: 'GET_ALL_OBJECTIFS',
    GET_EXPLORE_RESULTS: 'GET_EXPLORE_RESULTS',
    SET_PLAN_DATA: 'SET_PLAN_DATA',
    CHANGE_PLAN_SECTION: 'CHANGE_PLAN_SECTION',
    CREATE_NEW_PLAN: 'CREATE_NEW_PLAN',
    SET_EMPTY_PLAN: 'SET_EMPTY_PLAN',
    PLAN_LOADING: 'PLAN_LOADING',
    CREATE_PLAN_COMMENT: 'CREATE_PLAN_COMMENT',
    SET_PLAN_WEEK: 'SET_PLAN_WEEK',
    SET_NEW_PLAN: 'SET_NEW_PLAN',
    CREATE_PORTFOLIO_POST: 'CREATE_PORTFOLIO_POST',
    SET_PORTFOLIO_POSTS: 'SET_PORTFOLIO_POSTS',
    DELETE_POST: 'DELETE_POST'
}
