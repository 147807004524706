import React from 'react';
import PropTypes from 'prop-types';
import { withAITracking } from 'react-appinsights';

const home = "home";
const diagnostique = "Diagnostique"
const resultats = "résultats"
const journal = "Journal"
const portfolio = "portfolio"

const breadcrumbs = "breadcrumbs";
const appImg = "img";

// Function that take type and page as parameters. Type and page comes from props
function currentPageContent(type, page) {
  // Checking if the type is breadcrumbs
  if(type === breadcrumbs) {
    // Checking the page section and displaying the right breadcrumbs for that page
    if(page === home) {
      return <div className="d-flex flex-nowrap bd-highlight justify-content-end">
               <div className="pl-2 bd-highlight">
                 <div className="breadcrumbs-link py-3"><i className="fas fa-home mr-2"></i><h1>Accueil</h1></div>
               </div>
             </div>;
    } else if(page === diagnostique) {
      return <div className="d-flex flex-nowrap bd-highlight justify-content-end">
               <div className="pl-2 bd-highlight">
                 <div className="breadcrumbs-link py-3"><i className="fas fa-chart-area mr-2"></i><h1>Mon diagnostique</h1></div>
               </div>
             </div>;
    } else if(page === resultats) {
      return <div className="d-flex flex-nowrap bd-highlight justify-content-end">
                <div className="pl-2 bd-highlight">
                  <div className="breadcrumbs-link py-3"><i className="fas fa-stream mr-2"></i><h1>Mes résultats</h1></div>
                </div>
              </div>;
    } else if(page === journal) {
      return <div className="d-flex flex-nowrap bd-highlight justify-content-end">
                <div className="pl-2 bd-highlight">
                  <div className="breadcrumbs-link py-3"><i className="far fa-calendar-check mr-2"></i><h1>Mon journal d'entraînement</h1></div>
                </div>
              </div>;
    } else if(page === portfolio) {
      return <div className="d-flex flex-nowrap bd-highlight justify-content-end">
                <div className="pl-2 bd-highlight">
                  <div className="breadcrumbs-link py-3"><i className="fas fa-briefcase mr-2"></i><h1>Notes personnelles</h1></div>
                </div>
              </div>;
    }
  // Checking if type is image
  } else if(type === appImg) {
    // Checking the section and displaying the right image for that section
    if(page === home) {
      return <img className="img-fluid d-none d-md-block borderBottom" src="https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,g_center,h_800,w_5000/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_219920571.jpg" alt="" />
    } else if(page === diagnostique) {
      return <img className="img-fluid d-none d-md-block borderBottom" src="https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,g_center,h_700,w_5000/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_177932354.jpg" alt="" />
    } else if(page === resultats) {
      return <img className="img-fluid d-none d-md-block borderBottom" src="https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,h_700,w_5000/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_127139898.jpg" alt="" />
    } else if(page === journal) {
      return <img className="img-fluid d-none d-md-block borderBottom" src="https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,g_north,h_700,w_5000/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_92561687.jpg" alt="" />
    } else if(page === portfolio) {
      return <img className="img-fluid d-none d-md-block borderBottom" src="https://res.cloudinary.com/cforpmultimedia/image/upload/c_fill,h_700,w_5000/v1550170921/CoursModulaires/IQ005_OutilDiagnostique/AdobeStock_108888865.jpg" alt="" />
    }
  }
}

const ValidatePage = (props) => {
  return(
    currentPageContent(props.type, props.section)
  )
}

ValidatePage.propTypes = {
  type: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
}

export default withAITracking(ValidatePage);
