import React, { Component } from 'react';
import NavBar from '../../AppNav/NavBar';
import SideNav from '../../AppNav/SideNav';
import Logo from '../../AppNav/Logo';
import Wrapper from '../../Wrapper/Wrapper';
import ResultsList from '../../ResultsList/ResultsList';
import HistorySelect from '../../ResultsList/HistorySelect';
import History from '../../ResultsList/History';
import NavEnseignant from './NavEnseignant'
import constants from '../../../constants'
import moment from 'moment';
import { connect } from 'react-redux'
import axios from 'axios'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const current = "current";
const selectHistory = "selectHistory";

const teacher = "teacher";
const resultats = "résultats";

class ResultatsEnseignant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: "current", // current, selectHistory, history
      selectedHistoryDates: [],
    }
    this.showHistoryHandler = this.showHistoryHandler.bind(this);
  }

  componentDidMount() {
    let accessToken = this.props.auth.accessToken;

    // Gets student info from the location state
    const { studentFirstName, studentLastName, studentId } = this.props.location.state;

    // Sets student info in the state
    this.setState({studentFirstName: studentFirstName, studentLastName: studentLastName, studentId: studentId})

    // Gets student data from database
    this.props.setUserResults(accessToken, studentId);

  }

  // Checks if the teacher has selected at least two dates to compare results
  showHistoryHandler(values) {
    if(values.length < 2) {
      alert('Vous devez sélectionner au moins deux dates.');
    } else {
      // Set the dates in the state
      this.setState({
        current: "history",
        selectedHistoryDates: values
      })
    }
  }

  showCurrentResults(section) {

    // Declaring variable used to display content
    let view = null;

    // Putting all data results from state in a variable
    let stateResults = this.props.diagnostique;

    // Declaring new array that will contain the formated data results
    let newStateResults = []

    // Looping through all data results
    for(var i=0; i<stateResults.length; i++) {

      // Declaring variables that will contain sentence related to result
      let criteria1 = "";
      let criteria2 = "";
      let criteria3 = "";

      // Returning appropriate sentence for first criteria
      if(stateResults[i].surveyPoints[0] <= 7) {
        criteria1 = "Tu n’appliques pas les pratiques liées au conditionnement physique ce qui peut avoir un impact néfaste sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 7 && stateResults[i].surveyPoints[0] < 15) {
        criteria1 = "Tu appliques peu de pratiques liées au conditionnement physique ce qui peut avoir un impact négligeable sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 14 && stateResults[i].surveyPoints[0] < 22) {
        criteria1 = "À l’occasion, tu appliques des pratiques liées au conditionnement physique, ce qui peut avoir un impact appréciable sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 21 && stateResults[i].surveyPoints[0] < 29) {
        criteria1 = "Tu appliques régulièrement un bon nombre de pratiques liées au conditionnement physique ce qui peut être avantageux pour ton état de santé."
      } else {
        criteria1 = "Tu intègres les pratiques liées au conditionnement physique dans ton plan personnel d’entraînement et tu les appliques. Ceci peut être bénéfique pour ton état de santé."
      }

      // Returning appropriate sentence for second criteria
      if(stateResults[i].surveyPoints[1] <= 6) {
        criteria2 = "Tu ne mets pas en pratique des habitudes saines. Ceci peut avoir un impact néfaste sur ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 8 && stateResults[i].surveyPoints[1] < 12) {
        criteria2 = "Tu mets en pratique peu d’habitudes saines. Ceci peut nuire à ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 11 && stateResults[i].surveyPoints[1] < 18) {
        criteria2 = "À l’occasion, tu mets en pratique des habitudes saines qui peuvent avoir un impact appréciable sur ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 17 && stateResults[i].surveyPoints[1] < 24) {
        criteria2 = "Tu intègres un bon nombre d’habitudes saines dans ta vie, ce qui peut être favorable pour ton état de santé."
      } else {
        criteria2 = "Tu mets en pratiques, dans ton quotidien, des habitudes saines qui peuvent avoir un impact positif sur ton état de santé."
      }

      // Returning appropriate sentence for third criteria
      if(stateResults[i].surveyPoints[2] <= 7) {
        criteria3 = "Tu adoptes un comportement défavorable vis-à-vis l’entraînement. Ceci peut avoir un impact nuisible sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 7 && stateResults[i].surveyPoints[2] < 15) {
        criteria3 = "Tu adoptes un comportement peu favorable vis-à-vis l’entraînement. Ceci peut avoir un impact néfaste sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 14 && stateResults[i].surveyPoints[2] < 22) {
        criteria3 = "À l’occasion, tu adoptes un comportement favorable vis-à-vis l’entraînement. Ceci peut avoir un impact positif minimal sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 21 && stateResults[i].surveyPoints[2] < 29) {
        criteria3 = "Tu adoptes régulièrement un comportement favorable vis-à-vis l’entraînement. Ceci peut avoir un impact positif sur ton programme d’entraînement et ton état de santé."
      } else {
        criteria3 = "Tu adoptes toujours un comportement favorable vis-à-vis l’entraînement. Ceci facilite la mise en œuvre de ton programme d’entraînement et un état de santé optimal peut en découler."
      }

      // Pushing newly formated results to array
      newStateResults.push(
        {
          date: moment(stateResults[i].timestamp).format("LL"),
          conditions: criteria1,
          habitudes: criteria2,
          experiences: criteria3,
          data: stateResults[i].surveyPoints
        }
      )

    }

    // Returning only last result from state - database which represent the latest entry
    let onlyCurrentRes = [newStateResults[newStateResults.length - 1]];

    // Reverse items of 'newStateResults' array for display reasons
    let desNewStateRes = newStateResults.reverse();

    // Returning appropriate content depending on section of Results page
    if(section === current) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        if(newStateResults.length >= 2){
          view = <div id="main">
                {onlyCurrentRes.map((item, index) => <ResultsList results={item} index={index} key={index} collapse="show" radar="show" checkbox="" />)}
                <div className="col-12 col-sm-6 px-0">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={(event) => this.setState({ current: selectHistory })}><i className="fas fa-history mr-2"></i> Consulter l'historique de l'élève</button>
                </div>
              </div>
        } else {
          view = <Wrapper>
                {onlyCurrentRes.map((item, index) => <ResultsList results={item} index={index} key={index} collapse="show" radar="show" checkbox="" />)}
              </Wrapper>
        }
      } else {
        view = <div className="my-4">
                <div className="box my-3 text-center" id="main">
                   L'élève n'a répondu à aucun questionnaire de diagnostique.
                </div>
              </div>
      }
    } else if(section === selectHistory) {
      view = <div id="main">
                <HistorySelect results={desNewStateRes} clicked={this.showHistoryHandler} dates={this.state.selectedHistoryDates} return={(event) => this.setState({ current: current })} />
              </div>;
    } else {
      view = <div id="main">
                <History data={desNewStateRes} dates={this.state.selectedHistoryDates} />
                <div className="col-12 col-sm-6 px-0">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={(event) => this.setState({ current: current })}><i className="fas fa-chevron-left mr-2"></i> Revenir aux résultats</button>
                </div>
              </div>;
    }

    return view;

  }

  render() {

    // Returns the last part of the url which is the page name
    let path = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);

    const { studentFirstName, studentLastName, studentId } = this.state;

    return (
      <Wrapper>
        <header>
          <NavBar role={teacher} />
          <SideNav role={teacher} />
          <Logo section={resultats} />
        </header>
        <main className="container">
          <div className="mb-2 mt-5"><Link to="/" aria-label="retour à l'accueil"><i className="fas fa-home mr-2"></i> Accueil</Link></div>
          <div className="box p-3 mb-5 mt-2"><h1 className="m-0 studentHeader">{studentFirstName} {studentLastName}</h1></div>
          <NavEnseignant path={path} student={{firstname: studentFirstName, lastname: studentLastName, id: studentId}}/>
          {this.props.diagnostique !== null ? this.showCurrentResults(this.state.current) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
      </Wrapper>
    )

  }

}

function mapStateToProps(state) {
  return {
    auth: state.userAuth,
    student: state.teacher,
    diagnostique: state.diagnostique.results
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserResults: (accessToken, studentId) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'diagnostique', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          "For-Student": studentId
        }
      }).then(res => {
        dispatch({type: constants.GET_RESULTS, payload: res.data})
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({type: constants.GET_RESULTS, payload: []})
        }
      });
    }
  }
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(ResultatsEnseignant));
