import React from 'react'

// ModalContentObjectifs component's content
const SmartContent = (props) => {
  return(
    <div className="col-12 p-0">
      <div className="accordion" id="accordionExample">

        <div className="card mb-2">
          <div className="card-header p-0" id="headingOne">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              <p className="m-0 float-left">Directives</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body py-0">
              <ol className="p-4 m-0">
              <li>Lis la définition d'un objectif <em>Simple et spécifique</em> (S).</li>
              <li>En pensant à un de tes besoins prioritaires, lis les questions de réflexion qui se rapportent à cet aspect d'un objectif SMART.</li>
              <li>Dans la case OBJECTIF 1, écrit ton objectif pour ton besoin prioritaire. Assure-toi de répondre aux questions de réflexion, proposées pour cet aspect.</li>
              <li>Lis la définition et les questions de réflexion d'un objectif <em>mesurable</em> (M).</li>
              <li>Révise ton objectif 1 en tenant compte des caractéristiques précisées pour cet aspect.</li>
              <li>Fais le même exercice pour les lettres A, R et T.</li>
              <li>Reprends les étapes 1 à 6 pour tes deux autres besoins prioritaires en remplissant les cases OBJECTIF 2 et OBJECTIF 3.</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-header p-0" id="headingTwo">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <p className="m-0 float-left">S - Simple et spécifique</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div className="card-body">
              <h3>Définition</h3>
              <p className="m-0">L’objectif :</p>
              <ul>
                <li>est clair, précis, compréhensible et observable;</li>
                <li>énonce une action spécifique;</li>
                <li>vise un seul but.</li>
              </ul>
              <h3>Questions de réflexion</h3>
              <ul>
                <li>Quelle action dois-je entreprendre ou modifier?</li>
                <li>À quel moment ou à quelle fréquence dois-je mettre en pratique mon action?</li>
                <li>Quelle durée est-ce que je vise pour mon entraînement?</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-header p-0" id="headingThree">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <p className="m-0 float-left">M - Mesurable</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              <h3>Définition</h3>
              <p className="m-0">L’objectif :</p>
              <ul>
                <li>précise les indicateurs de réussite;</li>
                <li>peut-être quantifié ou qualifié;</li>
                <li>tient compte du point de départ et fixe une cible, souvent sous forme de chiffre.</li>
              </ul>
              <h3>Questions de réflexion</h3>
              <ul>
                <li>Comment vais-je savoir que j'ai atteint mon objectif?</li>
                <li>Quel est mon point de départ?</li>
                <li>Comment vais-je évaluer mes améliorations?</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-header p-0" id="headingFour">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <p className="m-0 float-left">A - Atteignable</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div className="card-body">
              <h3>Définition</h3>
              <p className="m-0">L’objectif :</p>
              <ul>
                <li>représente un défi stimulant;</li>
                <li>est concret;</li>
                <li>permet de s’améliorer progressivement.</li>
              </ul>
              <h3>Questions de réflexion</h3>
              <ul>
                <li>Est-ce que mon objectif me donne la possibilité de me surpasser?</li>
                <li>Ai-je la possibilité de m’améliorer tout au long de mon parcours?</li>
                <li>Est-ce que mon objectif est concret, autrement dit, est-il réalisable dans le temps que je me suis fixé?</li>
                <li>Suis-je motivée ou motivé par mon objectif?</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-header p-0" id="headingFive">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              <p className="m-0 float-left">R - Réaliste</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
            <div className="card-body">
              <h3>Définition</h3>
              <p className="m-0">L’objectif :</p>
              <ul>
                <li>est réalisable;</li>
                <li>est raisonnable par rapport aux ressources disponibles, aux compétences de la personne et à l’ensemble de ses activités quotidiennes;</li>
                <li>vise à améliorer la santé.</li>
              </ul>
              <h3>Questions de réflexion</h3>
              <ul>
                <li>Quelles sont les ressources (physiques et humaines) dont j’ai besoin pour réaliser mon objectif? Sont-elles faciles d'accès?</li>
                <li>Quels sont les sacrifices ou les changements que je devrai faire? Est-ce raisonnable?</li>
                <li>Combien de fois par jour ou par semaine devrai-je mettre en pratique mon plan ? Est-ce faisable?</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-header p-0" id="headingSix">
            <button className="btn btn-link w-100 mb-0 p-2" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              <p className="m-0 float-left">T - Temporel</p>
              <span className="purpleColor fas fa-chevron-down float-right p-1"></span>
            </button>
          </div>
          <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
            <div className="card-body">
            <h3>Définition</h3>
              <p>L’objectif précise une date d’échéance.</p>
              <h3>Questions de réflexion</h3>
              <ul>
                <li>Combien de temps ai-je pour atteindre mon objectif?</li>
                <li>Est-ce que la date visée me donne suffisamment de temps pour réaliser mon objectif?</li>
                <li>La date visée me donne-t-elle l’opportunité de démontrer et de mesurer mon progrès?</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SmartContent;
