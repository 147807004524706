import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../Wrapper/Wrapper';
import { Link } from 'react-router-dom'
import fistLogo1 from '../../img/logo-1line.svg';
import fistLogo2 from '../../img/logo-2line.svg';
import secondLogo from '../../img/navSecondImg.png';
import { withAITracking } from 'react-appinsights';

const Logo = (props) => {

  let isHome = null;

  // Checking if on home page
  if(props.section === "home") {
    // Showing bigger logo on home page
    isHome = <Wrapper>
              <div className="col-6 col-sm-6 col-md-4 col-lg-3 px-auto pr-0 align-self-center text-center">
                <Link to="/" aria-hidden="true" tabIndex="-1"><img src={fistLogo2} className="img-fluid" alt="" /></Link>
              </div>
              <div className="col-6 col-sm-6 col-md-8 col-lg-9 px-auto pl-0 align-self-center text-right">
                <img src={secondLogo} className="img-fluid" width="125px" alt="" />
              </div>
            </Wrapper>;
  } else {
    // Showing smaller logo on every other pages
    isHome = <Wrapper>
              <div className="col-6 col-sm-6 col-md-5 col-lg-4 px-auto pr-0 align-self-center text-center">
                <Link to="/" aria-hidden="true" tabIndex="-1"><img src={fistLogo1} className="img-fluid" alt="" /></Link>
              </div>
              <div className="col-6 col-sm-6 col-md-7 col-lg-8 px-auto pl-0 align-self-center text-right">
                <img src={secondLogo} className="img-fluid" width="75px" alt="" />
              </div>
            </Wrapper>;
  }

  return (
    <Wrapper>
      <hr />
      <div className="col-12 logoBox redBorder px-0">
        <div className="container">
          <div className="row py-2">
            {isHome}
          </div>
        </div>
      </div>
    </Wrapper>
  )

}

Logo.propTypes = {
  section: PropTypes.string.isRequired
}

export default withAITracking(Logo);
