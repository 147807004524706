import React from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import SmartContent from './SmartContent'
import { withAITracking } from 'react-appinsights';

// Renders modal content in Objectifs SMART
const ModalContentObjectifs = () => {
  return (
    <Wrapper>
      <div className="row mb-3">
        <div className="col-6">
          <span className="far fa-question-circle fa-2x purpleColor"></span>
        </div>
        <div className="col-6 text-right">
          <button type="button" className="btn btn-outline-purple" data-dismiss="modal" aria-label="Fermer la boite de dialogue">Fermer</button>
        </div>
      </div>
      <div className="box mb-4">
        <div className="col-12 px-1">
          <h2 className="surveySectionTitle">Objectifs SMART</h2>
        </div>
      </div>
      <SmartContent />
    </Wrapper>
  )
}

export default withAITracking(ModalContentObjectifs);
