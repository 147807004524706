import React, {Component} from 'react';
import NavBar from '../../AppNav/NavBar';
import SideNav from '../../AppNav/SideNav';
import Logo from '../../AppNav/Logo';
import Wrapper from '../../Wrapper/Wrapper';
import JournalActivities from '../../ResultsList/JournalActivities'
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import surveyExplore from '../../../surveys/surveyExplore'
import NavEnseignant from './NavEnseignant'
import moment from 'moment';
import { connect } from 'react-redux'
import constants from '../../../constants'
import PropTypes from 'prop-types';
import axios from 'axios'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const results = "results";
const history = "history";

const teacher = "teacher";
const resultats = "résultats";

class ExploreEnseignant extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentWillMount() {
    Survey.Survey.cssType = "bootstrap";
    Survey.defaultBootstrapCss.navigationButton = "btn";
    Survey.defaultBootstrapCss.navigation.complete = "journalCompleteBtn";
    Survey.defaultBootstrapCss.navigation.prev = "journalPrevBtn";
    Survey.defaultBootstrapCss.navigation.next = "journalNextBtn";
  }

  componentDidMount() {
    let accessToken = this.props.userAuth.accessToken;

    // Gets student info from the location state
    const { studentFirstName, studentLastName, studentId } = this.props.location.state;

    // Sets student info in the state
    this.setState({studentFirstName: studentFirstName, studentLastName: studentLastName, studentId: studentId})

    // Gets student data from database
    this.props.getExploreResults(accessToken, studentId)
  }

  exploreSectionHandler(section) {

    // Creating survey questions with json
    var survey = new Survey.Model(surveyExplore);
    survey.showQuestionNumbers = "off";
    survey.requiredText = "";

    let view = null;

    // Creating array of all section title - will loop through
    const surveySections = ["Échauffement","Endurance cardiorespiratoire","Musculation","Flexibilité","Retour au calme","Objectifs liés a mes habitudes saines et à mes attitudes"];

    // Getting results from state
    let stateResults = this.props.explore.results;

    // Creating new arrays that will contain state results with new formating
    let newStateResults = [];
    let onlyCurrentRes = [];

    // Looping through all state results
    for(var i=0; i<stateResults.length; i++) {

      // initializing new array that will contain only title, ressources and activities
      let newStateContent = [];

      // Looping to add title, ressources and activities of current state result
      for(var n=0; n<surveySections.length; n++) {
        newStateContent.push(
          {
            title: surveySections[n],
            ressources: stateResults[i].section[n].ressources,
            activities: stateResults[i].section[n].activities
          }
        );
      }

      // Creating array for all state results
      newStateResults.push(
        {
          date: moment(stateResults[i].timestamp).format("LL"),
          section: newStateContent
        }
      );

      // Checking if we are in the last iteration of the loop
      if(i === stateResults.length - 1) {
        // Itinializing new array that will contain only title, ressources and activities of the most recent entry
        let onlyCurrentContent = [];
        // Looping to add title, ressources and activities of most recent entry in state result
        for(var j=0; j<surveySections.length; j++){
          onlyCurrentContent.push(
            {
              title: surveySections[j],
              ressources: stateResults[i].section[j].ressources,
              activities: stateResults[i].section[j].activities
            }
          );
        }

        // Creating array for most recent entry
        onlyCurrentRes.push(
          {
            date: moment(stateResults[i].timestamp).format("LL"),
            section: onlyCurrentContent
          }
        );

      }

    }

    // Reverse items of 'newStateResults' array to display in the right order
    let desNewStateRes = newStateResults.reverse();

    // Render appropriate section depending on state section
    if(section === history) {
      view = <div id="main">
              {desNewStateRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
              <div className="row">
                <div className="col-12 col-md-6">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(results)}><i className="fas fa-chevron-left mr-2"></i> Revenir aux activités</button>
                </div>
              </div>
             </div>
    } else if(section === results) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        if(newStateResults.length >= 2){
          view = <div id="main">
                  {onlyCurrentRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(history)}><i className="fas fa-history mr-2"></i> Consulter l'historique de l'élève</button>
                    </div>
                  </div>
                 </div>
        } else {
          view = <div id="main">
                  {onlyCurrentRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
                 </div>
        }
      } else {
        view = <div className="my-4">
                <div className="box my-3 text-center" id="main">
                   L'élève n'a pas rempli cette section.
                </div>
              </div>
      }

    }

    return view;
  }

  render() {

    // Returns the last part of the url which is the page name
    const path = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);

    const { studentFirstName, studentLastName, studentId } = this.state;

    return (
      <Wrapper>
        <header>
          <NavBar role={teacher} />
          <SideNav role={teacher} />
          <Logo section={resultats} />
        </header>
        <main className="container">
          <div className="mb-2 mt-5"><Link to="/" aria-label="retour à l'accueil"><i className="fas fa-home mr-2"></i> Accueil</Link></div>
          <div className="box p-3 mb-5 mt-2"><h1 className="m-0 studentHeader">{studentFirstName} {studentLastName}</h1></div>
          <NavEnseignant path={path} student={{firstname: studentFirstName, lastname: studentLastName, id: studentId}}/>
          {this.props.explore.results !== null ? this.exploreSectionHandler(this.props.explore.currentView) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    explore: state.explore,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch){
  return {
    setActivitiesSection: (section) => {
      dispatch({ type: constants.SET_ACTIVITIES_SECTION, payload: section })
    },
    getExploreResults: (accessToken, studentId) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'explore', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          "For-Student": studentId
        }
      }).then(res => {
        dispatch({ type: constants.GET_EXPLORE_RESULTS, payload: res.data })
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({ type: constants.GET_EXPLORE_RESULTS, payload: [] })
        }
      });
    }
  }
}

ExploreEnseignant.propTypes = {
  explore: PropTypes.object.isRequired,
  setActivitiesSection: PropTypes.func.isRequired
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(ExploreEnseignant));
