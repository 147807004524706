import React, { Component } from 'react';
import AppNav from '../AppNav/AppNav';
import Instructions from '../Instructions/Instructions'
import Wrapper from '../Wrapper/Wrapper';
import ResultsList from '../ResultsList/ResultsList';
import HistorySelect from '../ResultsList/HistorySelect';
import History from '../ResultsList/History';
import axios from 'axios'
import moment from 'moment';
import { connect } from 'react-redux'
import constants from '../../constants'
import gif from '../../img/IQ5_Animation_Loading_Gris.gif'
import { withAITracking } from 'react-appinsights';

const current = "current";
const selectHistory = "selectHistory";

class Resultats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: "current", // current, selectHistory, history
      selectedHistoryDates: [],
      loading: false,
    }
    this.showHistoryHandler = this.showHistoryHandler.bind(this);
  }

  componentDidMount() {
    // Putting accessToken in variable
    let accessToken = this.props.auth.accessToken;

    if(this.props.diagnostique === null){
      // Calling action to get all diagnostique results from database
      this.props.getResults(accessToken);
    }

  }

  showHistoryHandler(values) {
    if(values.length < 2) {
      alert('Vous devez sélectionner au moins deux dates.');
    } else {
      this.setState({
        current: "history",
        selectedHistoryDates: values
      })
    }
  }

  showCurrentResults(section) {

    // Declaring variable used to display content
    let view = null;

    // Putting all data results from state in a variable
    let stateResults = this.props.diagnostique;

    // Declaring new array that will contain the formated data results
    let newStateResults = []

    // Looping through all data results
    for(var i=0; i<stateResults.length; i++) {

      // Declaring variables that will contain sentence related to result
      let criteria1 = "";
      let criteria2 = "";
      let criteria3 = "";

      // Returning appropriate sentence for first criteria
      if(stateResults[i].surveyPoints[0] <= 7) {
        criteria1 = "Tu n’appliques pas les pratiques liées au conditionnement physique, ce qui peut avoir des effets néfastes sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 7 && stateResults[i].surveyPoints[0] < 15) {
        criteria1 = "Tu appliques peu de pratiques liées au conditionnement physique, ce qui peut avoir des effets négligeables sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 14 && stateResults[i].surveyPoints[0] < 22) {
        criteria1 = "À l’occasion, tu appliques des pratiques liées au conditionnement physique, ce qui peut avoir des effets plutôt favorables sur ton état de santé."
      } else if (stateResults[i].surveyPoints[0] > 21 && stateResults[i].surveyPoints[0] < 29) {
        criteria1 = "Tu appliques régulièrement un bon nombre de pratiques liées au conditionnement physique, ce qui peut être avantageux pour ton état de santé."
      } else {
        criteria1 = "Tu intègres les pratiques liées au conditionnement physique à ton plan personnel d’entraînement et tu les appliques. Cela peut être bénéfique pour ton état de santé."
      }

      // Returning appropriate sentence for second criteria
      if(stateResults[i].surveyPoints[1] <= 6) {
        criteria2 = "Tu ne mets pas en pratique des habitudes saines. Cela peut avoir des effets néfastes sur ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 8 && stateResults[i].surveyPoints[1] < 12) {
        criteria2 = "Tu mets en pratique peu d’habitudes saines. Cela peut nuire à ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 11 && stateResults[i].surveyPoints[1] < 18) {
        criteria2 = "À l’occasion, tu mets en pratique des habitudes saines qui peuvent avoir des effets plutôt favorables pour ton état de santé."
      } else if (stateResults[i].surveyPoints[1] > 17 && stateResults[i].surveyPoints[1] < 24) {
        criteria2 = "Tu intègres un bon nombre d’habitudes saines à ta vie, ce qui peut être favorable pour ton état de santé."
      } else {
        criteria2 = "Tu mets en pratique, dans ton quotidien, des habitudes saines qui peuvent avoir des effets positifs sur ton état de santé."
      }

      // Returning appropriate sentence for third criteria
      if(stateResults[i].surveyPoints[2] <= 7) {
        criteria3 = "Tu adoptes un comportement défavorable vis-à-vis l’entraînement. Cela peut avoir des effets nuisibles sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 7 && stateResults[i].surveyPoints[2] < 15) {
        criteria3 = "Tu adoptes un comportement peu favorable vis-à-vis l’entraînement. Cela peut avoir des effets néfastes sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 14 && stateResults[i].surveyPoints[2] < 22) {
        criteria3 = "À l’occasion, tu adoptes un comportement favorable vis-à-vis l’entraînement. Cela peut avoir des effets positifs minimaux sur ton programme d’entraînement et ton état de santé."
      } else if (stateResults[i].surveyPoints[2] > 21 && stateResults[i].surveyPoints[2] < 29) {
        criteria3 = "Tu adoptes régulièrement un comportement favorable vis-à-vis l’entraînement. Cela peut avoir des effets positifs sur ton programme d’entraînement et ton état de santé."
      } else {
        criteria3 = "Tu adoptes toujours ou presque toujours un comportement favorable vis-à-vis l’entraînement. Cela facilite la mise en œuvre de ton programme d’entraînement et un état de santé optimal peut en découler."
      }

      // Pushing newly formated results to array
      newStateResults.push(
        {
          date: moment(stateResults[i].timestamp).format("LL"),
          conditions: criteria1,
          habitudes: criteria2,
          experiences: criteria3,
          data: stateResults[i].surveyPoints
        }
      )

    }

    // Returning only last result from state - database which represent the latest entry
    let onlyCurrentRes = [newStateResults[newStateResults.length - 1]];

    // Reverse items of 'newStateResults' array for display reasons
    let desNewStateRes = newStateResults.reverse();

    // Returning appropriate content depending on section of Results page
    if(section === current) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        // Renders results if there are 2 or more entries in the database
        if(newStateResults.length >= 2){
          view = <Wrapper>
                  <Instructions>
                    <p className="m-0">Tu trouveras ci-dessous une description et une représentation graphique de tes résultats pour chaque thème du sondage. </p>
                  </Instructions>
                {onlyCurrentRes.map((item, index) => <ResultsList results={item} index={index} key={index} collapse="show" radar="show" checkbox="" radarModal={this.openRadarModal} />)}
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={(event) => this.setState({ current: selectHistory })}><i className="fas fa-history mr-2"></i> Consulter mon historique</button>
                  </div>
                </div>
              </Wrapper>
        // Renders results if there is only one entry in the database
        } else {
          view = <Wrapper>
                  <Instructions>
                    <p className="m-0">Tu trouveras ci-dessous une description et une représentation graphique de tes résultats pour chaque thème du sondage. </p>
                  </Instructions>
                {onlyCurrentRes.map((item, index) => <ResultsList results={item} index={index} key={index} collapse="show" radar="show" checkbox="" />)}
              </Wrapper>
        }
      // Renders this message if there is no entry in the database
      } else {
        view = <div className="my-4">
                <div className="box my-3 text-center">
                  Vous n'avez pas encore de résultats. Rendez-vous à la section « Diagnostique » pour répondre au questionnaire.
                </div>
              </div>
      }
    // Renders this HistorySelect component which lets the user select 2 or 3 dates to view at the same time
    } else if(section === selectHistory) {
      view = <Wrapper>
                <Instructions>
                  <p className="m-0">Chaque fois que tu remplis le sondage, tes résultats sont sauvegardés ici. Ainsi, tu peux comparer le profil de ton état santé d’un sondage à l’autre. Clique pour sélectionner un maximum de trois cases afin d’obtenir une représentation graphique des résultats des sondages sélectionnés. </p>
                </Instructions>
                <HistorySelect results={desNewStateRes} clicked={this.showHistoryHandler} dates={this.state.selectedHistoryDates} return={(event) => this.setState({ current: current })} />
              </Wrapper>;
    } else {
      // Renders the 2 or 3 entries chosen by the user in the graph
      view = <Wrapper>
                <Instructions>
                  <p className="m-0">Tu trouveras ci-dessous une description et une représentation graphique de tes résultats pour chaque thème du sondage.</p>
                </Instructions>
                <History data={desNewStateRes} dates={this.state.selectedHistoryDates} />
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={(event) => this.setState({ current: current })}><i className="fas fa-chevron-left mr-2"></i> Revenir à mes résultats</button>
                  </div>
                </div>
              </Wrapper>;
    }

    return view;

  }

  render() {



    return (
      <Wrapper>
        <AppNav section="résultats" auth={this.props.auth} />
        <main className="container" id="main">
          {this.props.diagnostique !== null ? this.showCurrentResults(this.state.current) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
      </Wrapper>
    )

  }

}

function mapStateToProps(state) {
  return {
    diagnostique: state.diagnostique.results,
    auth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getResults: (accessToken) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'diagnostique', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {
        dispatch({type: constants.GET_RESULTS, payload: res.data})
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({type: constants.GET_RESULTS, payload: {}})
        }
      });
    }
  }
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(Resultats));
