import React, { Component } from 'react';
import { connect } from 'react-redux'
import Wrapper from '../../Wrapper/Wrapper'
import gif from '../../../img/IQ5_Animation_Loading_Blanc.gif'
import constants from '../../../constants'


class PlanForm extends Component {

  inputChangeHandler(e) {
    // Getting copy of state
    let data = JSON.parse(JSON.stringify(this.props.plan.results));

    let currentRes = null;
    // Getting last results (last plan in database)
    for(var i=data.length-1; i>=data.length-1; i--) {
      currentRes = data[i]
    }

    // Setting week as the same index as the state
    let weekInResults = this.props.week-1;
    // Retrieving week day with target.name
    let weekDay = e.target.name;

    let input = "INPUT";
    let textarea = "TEXTAREA";

    // Checking if the input the user changed is a textarea or input (checkbox)
    if(e.target.nodeName === textarea) {

      // Setting the value of the appropriate day in the copy of the state
      currentRes.data[weekInResults][weekDay].text = e.target.value

      // Updating current results
      for(var g=data.length-1; g>=data.length-1; g--) {
        data[g] = currentRes;
      }

    } else if(e.target.nodeName === input) {

      // Get the checkbox by id
      let item = document.getElementById(e.target.id);
      if(item.checked) {
        // Check the check box if it's not already
        item.checked = true;
        currentRes.data[weekInResults][weekDay].checked = true;

        // Updating current results
        for(var h=data.length-1; h>=data.length-1; h--) {
          data[h] = currentRes;
        }
      } else {
        // Uncheck the check box if it's checked
        item.checked = false;
        currentRes.data[weekInResults][weekDay].checked = false;

        // Updating current results
        for(var j=data.length-1; j>=data.length-1; j--) {
          data[j] = currentRes;
        }
      }

    }

    this.props.updatePlanData(data);

  }

  render() {

    // Returns the index of the current week
    let week = this.props.week-1;

    let res = this.props.plan.results;

    let view = null;

    if(res !== null){
      let currentRes = null;

      // Returns the current results
      for(var i=res.length-1; i>=res.length-1; i--) {
        currentRes = res[i]
      }
      view = <div className="col-12 tab-content p-4">

        {/* Renders the textarea and checkbox for each day of the current week */}
        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour1`}>{currentRes.data[week].jour1.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour1" className="form-control" id={`semaine${this.props.week}-jour1`} aria-label={currentRes.data[week].jour1.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour1.checked ? "readonly" : ""} value={currentRes.data[week].jour1.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour1.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour1.checked ? `Activités du ${currentRes.data[week].jour1.date} complété` : `Activités du ${currentRes.data[week].jour1.date} non complété`} id={`semaine${this.props.week}-jour1-check`} name="jour1" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour1-check`} id={`label${this.props.week}-jour1`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour2`}>{currentRes.data[week].jour2.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour2" className="form-control" id={`semaine${this.props.week}-jour2`} aria-label={currentRes.data[week].jour2.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour2.checked ? "readOnly" : ""} value={currentRes.data[week].jour2.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour2.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour2.checked ? `Activités du ${currentRes.data[week].jour2.date} complété` : `Activités du ${currentRes.data[week].jour2.date} non complété`} id={`semaine${this.props.week}-jour2-check`} name="jour2" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour2-check`} id={`label${this.props.week}-jour2`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour3`}>{currentRes.data[week].jour3.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour3" className="form-control" id={`semaine${this.props.week}-jour3`} aria-label={currentRes.data[week].jour3.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour3.checked ? "readOnly" : ""} value={currentRes.data[week].jour3.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour3.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour3.checked ? `Activités du ${currentRes.data[week].jour3.date} complété` : `Activités du ${currentRes.data[week].jour3.date} non complété`} id={`semaine${this.props.week}-jour3-check`} name="jour3" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour3-check`} id={`label${this.props.week}-jour3`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour4`}>{currentRes.data[week].jour4.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour4" className="form-control" id={`semaine${this.props.week}-jour4`} aria-label={currentRes.data[week].jour4.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour4.checked ? "readOnly" : ""} value={currentRes.data[week].jour4.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour4.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour4.checked ? `Activités du ${currentRes.data[week].jour4.date} complété` : `Activités du ${currentRes.data[week].jour4.date} non complété`} id={`semaine${this.props.week}-jour4-check`} name="jour4" className="custom-control-input"  onChange={(e) => this.inputChangeHandler(e)}/>
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour4-check`} id={`label${this.props.week}-jour4`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour5`}>{currentRes.data[week].jour5.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour5" className="form-control" id={`semaine${this.props.week}-jour5`} aria-label={currentRes.data[week].jour5.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour5.checked ? "readOnly" : ""} value={currentRes.data[week].jour5.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour5.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour5.checked ? `Activités du ${currentRes.data[week].jour5.date} complété` : `Activités du ${currentRes.data[week].jour5.date} non complété`} id={`semaine${this.props.week}-jour5-check`} name="jour5" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour5-check`} id={`label${this.props.week}-jour5`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour6`}>{currentRes.data[week].jour6.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour6" className="form-control" id={`semaine${this.props.week}-jour6`} aria-label={currentRes.data[week].jour6.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour6.checked ? "readOnly" : ""} value={currentRes.data[week].jour6.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour6.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour6.checked ? `Activités du ${currentRes.data[week].jour6.date} complété` : `Activités du ${currentRes.data[week].jour6.date} non complété`} id={`semaine${this.props.week}-jour6-check`} name="jour6" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour6-check`} id={`label${this.props.week}-jour6`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor={`semaine${this.props.week}-jour7`}>{currentRes.data[week].jour7.date}</label>
          <div className="input-group mb-3">
            <textarea name="jour7" className="form-control" id={`semaine${this.props.week}-jour7`} aria-label={currentRes.data[week].jour7.date} rows="4" onChange={(e) => this.inputChangeHandler(e)} readOnly={currentRes.data[week].jour7.checked ? "readOnly" : ""} value={currentRes.data[week].jour7.text}></textarea>
            <div className="input-group-append">
              <div className="input-group-text">
                <div className="custom-control custom-checkbox">
                  <input checked={currentRes.data[week].jour7.checked ? true : false} type="checkbox"
                  aria-label={currentRes.data[week].jour7.checked ? `Activités du ${currentRes.data[week].jour7.date} complété` : `Activités du ${currentRes.data[week].jour7.date} non complété`} id={`semaine${this.props.week}-jour7-check`} name="jour7" className="custom-control-input" onChange={(e) => this.inputChangeHandler(e)} />
                  <label className="custom-control-label" htmlFor={`semaine${this.props.week}-jour7-check`} id={`label${this.props.week}-jour7`}></label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    } else {
      view = <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />
    }

    return (
      <Wrapper>
        {view}
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    plan: state.plan,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePlanData: (data) => {
      dispatch({ type: constants.SET_PLAN_DATA, payload: data });
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PlanForm);
