import React, {Component} from 'react';
import AppNav from '../../AppNav/AppNav';
import Instructions from '../../Instructions/Instructions'
import Wrapper from '../../Wrapper/Wrapper';
import SurveySteps from '../../SurveySteps/SurveySteps';
import JournalActivities from '../../ResultsList/JournalActivities'
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import surveyExplore from '../../../surveys/surveyExplore'
import moment from 'moment';
import { connect } from 'react-redux'
import constants from '../../../constants'
import PropTypes from 'prop-types';
import axios from 'axios'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { withAITracking } from 'react-appinsights';

const form = "form";
const success = "success";
const results = "results";
const history = "history";

const journalSectionPath = ["/mon-journal-d-entrainement/objectifs-smart", "/mon-journal-d-entrainement/j-explore-je-choisis", "/mon-journal-d-entrainement/plan-d-entrainement"];

class Explore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surveyComplete: false
    }

    this.onComplete = this.onComplete.bind(this);
  }

  componentWillMount() {
    Survey.Survey.cssType = "bootstrap";
    Survey.defaultBootstrapCss.navigationButton = "btn";
    Survey.defaultBootstrapCss.navigation.complete = "journalCompleteBtn";
    Survey.defaultBootstrapCss.navigation.prev = "journalPrevBtn";
    Survey.defaultBootstrapCss.navigation.next = "journalNextBtn";
  }

  componentDidMount() {
    // Getting accessToken from redux store
    let accessToken = this.props.userAuth.accessToken;

    this.props.getExploreResults(accessToken)

  }

  onComplete(survey) {

    // Get all results from store
    let newData = JSON.parse(JSON.stringify(this.props.explore.results));

    // putting answers from user in array
    let surveyResults = [
      {
        ressources: survey.data.question1,
        activities: survey.data.question2
      },
      {
        ressources: survey.data.question3,
        activities: survey.data.question4
      },
      {
        ressources: survey.data.question5,
        activities: survey.data.question6
      },
      {
        ressources: survey.data.question7,
        activities: survey.data.question8
      },
      {
        ressources: survey.data.question9,
        activities: survey.data.question10
      },
      {
        ressources: survey.data.question11,
        activities: survey.data.question12
      }
    ]

    if(newData.length >= 1) {
      // If there is one or more result in store we loop through the results to get the last result
      for(var i = newData.length - 1; i >= newData.length - 1; i--){
        // Transforming last item timestamp to date
        let lastItemDate = moment(newData[i].timestamp).format("LL");
        if(lastItemDate === moment().format("LL")) {
          // If last item date is the same as todays date, we update that data with the new data
          newData[i] = {
            timestamp: Date.now(),
            section: surveyResults
          }
        } else {
          // If last item date is not the same as todays date, we push data to array with all other results
          newData.push(
            {
              timestamp: Date.now(),
              section: surveyResults
            }
          )
        }
      }
    } else {
      // If there is no results, we create the first result
      newData.push(
        {
          timestamp: Date.now(),
          section: [
            {
              ressources: survey.data.question1,
              activities: survey.data.question2
            },
            {
              ressources: survey.data.question3,
              activities: survey.data.question4
            },
            {
              ressources: survey.data.question5,
              activities: survey.data.question6
            },
            {
              ressources: survey.data.question7,
              activities: survey.data.question8
            },
            {
              ressources: survey.data.question9,
              activities: survey.data.question10
            },
            {
              ressources: survey.data.question11,
              activities: survey.data.question12
            }
          ]
        }
      )
    }

    // Creating new object to send in redux store
    let newstate = {
      currentView: success,
      results: newData
    }

    // Getting accessToken and bonjour_person_id from redux store
    let accessToken = this.props.userAuth.accessToken;
    let bonjour_person_id = this.props.userAuth.profile.bonjour_person_id;

    // Calling action to create new activity
    this.props.setNewActivities(newstate, accessToken, bonjour_person_id);
  }

  exploreSectionHandler(section) {

    // Creating survey questions with json
    var survey = new Survey.Model(surveyExplore);
    survey.showQuestionNumbers = "off";
    survey.requiredText = "";

    let view = null;

    // Creating array of all section title - will loop through
    const surveySections = ["Échauffement","Endurance cardiorespiratoire","Musculation","Flexibilité","Retour au calme","Objectifs liés a mes habitudes saines et à mes attitudes"];

    // Getting results from state
    let stateResults = JSON.parse(JSON.stringify(this.props.explore.results));

    // Creating new arrays that will contain state results with new formating
    let newStateResults = [];
    let onlyCurrentRes = [];

    // Looping through all state results
    for(var i=0; i<stateResults.length; i++) {

      // initializing new array that will contain only title, ressources and activities
      let newStateContent = [];

      // Looping to add title, ressources and activities of current state result
      for(var n=0; n<surveySections.length; n++) {
        newStateContent.push(
          {
            title: surveySections[n],
            ressources: stateResults[i].section[n].ressources,
            activities: stateResults[i].section[n].activities
          }
        );
      }

      // Creating array for all state results
      newStateResults.push(
        {
          date: moment(stateResults[i].timestamp).format("LL"),
          section: newStateContent
        }
      );

      // Checking if we are in the last iteration of the loop
      if(i === stateResults.length - 1) {
        // Itinializing new array that will contain only title, ressources and activities of the most recent entry
        let onlyCurrentContent = [];
        // Looping to add title, ressources and activities of most recent entry in state result
        for(var j=0; j<surveySections.length; j++){
          onlyCurrentContent.push(
            {
              title: surveySections[j],
              ressources: stateResults[i].section[j].ressources,
              activities: stateResults[i].section[j].activities
            }
          );
        }

        // Creating array for most recent entry
        onlyCurrentRes.push(
          {
            date: moment(stateResults[i].timestamp).format("LL"),
            section: onlyCurrentContent
          }
        );

      }

    }

    // Reverse items of 'newStateResults' array to display in the right order
    let desNewStateRes = newStateResults.reverse();

    // Render appropriate section depending on state section
    if(section === history) {
      view = <Wrapper>
              {desNewStateRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
              <div className="row">
                <div className="col-12 col-sm-6">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(results)}><i className="fas fa-chevron-left mr-2"></i> Retour à mes activités</button>
                </div>
              </div>

             </Wrapper>
    } else if(section === results) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        // Renders explore data if there is 2 or more entries in the database
        if(newStateResults.length >= 2){
          view = <Wrapper>
                  <Instructions>
                    <p>Quelles activités ou quels exercices vais-je incorporer à mon plan personnel d’entraînement?</p>

                    <p className="m-0">Effectue une recherche pour chaque composante de ton plan d’entraînement : échauffement, endurance cardiorespiratoire, musculation, flexibilité, retour au calme, objectifs relatifs à d'autres aspects que la condition physique (p. ex., objectifs liés aux habitudes saines ou aux attitudes). Puise dans tes idées, dans ton expérience et dans les ressources à ta disposition pour choisir les activités, les exercices et les actions qui te permettront d’améliorer ou de maintenir ta condition physique en plus d’atteindre les objectifs que tu as ciblés. Note les ressources consultées lors de ta recherche (nom, hyperlien, etc.). Assure-toi qu’il s’agit de ressources fiables. Inscris tes choix d’activités, d’exercices et d’actions.</p>
                  </Instructions>
                  {onlyCurrentRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(history)}><i className="fas fa-history mr-2"></i> Consulter mon historique</button>
                    </div>
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(form)}><i className="fas fa-plus mr-2"></i> Explorer et choisir de nouveaux exercices</button>
                    </div>
                  </div>
                 </Wrapper>
        // Renders explore data if there is only 1 entry in the database
        } else {
          view = <Wrapper>
                  <Instructions>
                    <p>Quelles activités ou quels exercices vais-je incorporer à mon plan personnel d’entraînement?</p>

                    <p className="m-0">Effectue une recherche pour chaque composante de ton plan d’entraînement : échauffement, endurance cardiorespiratoire, musculation, flexibilité, retour au calme, objectifs relatifs à d'autres aspects que la condition physique (p. ex., objectifs liés aux habitudes saines ou aux attitudes). Puise dans tes idées, dans ton expérience et dans les ressources à ta disposition pour choisir les activités, les exercices et les actions qui te permettront d’améliorer ou de maintenir ta condition physique en plus d’atteindre les objectifs que tu as ciblés. Note les ressources consultées lors de ta recherche (nom, hyperlien, etc.). Assure-toi qu’il s’agit de ressources fiables. Inscris tes choix d’activités, d’exercices et d’actions.</p>
                  </Instructions>
                  {onlyCurrentRes.map((item, index) => <JournalActivities results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setActivitiesSection(form)}><i className="fas fa-plus mr-2"></i> Explorer et choisir de nouveaux exercices</button>
                    </div>
                  </div>
                 </Wrapper>
        }
      // If there is no entries in the database it triggers a change of section to show the form to create an entry
      } else {
        this.props.setActivitiesSection(form);
      }
    // Renders if the user successfully creates a new entry in the database
    } else if(section === success){
        view = <Wrapper>
                <div className="box my-3">
                  <p className="completedSurveyText m-0">Vos réponses ont été sauvegardées.</p>
                </div>
                <div className="col-12 text-center">
                  <button className="btn btn-outline-purple px-3 my-4" onClick={() => this.props.setActivitiesSection(results)}><i className="fas fa-stream mr-2"></i> Voir mes activités</button>
                </div>
              </Wrapper>
    // If there is no entries in the database it triggers the form to create an entry
    } else {
        view = <Wrapper>
                <Instructions>
                  <p>Quelles activités ou quels exercices vais-je incorporer à mon plan personnel d’entraînement?</p>

                  <p className="m-0">Effectue une recherche pour chaque composante de ton plan d’entraînement : échauffement, endurance cardiorespiratoire, musculation, flexibilité, retour au calme, objectifs relatifs à d'autres aspects que la condition physique (p. ex., objectifs liés aux habitudes saines ou aux attitudes). Puise dans tes idées, dans ton expérience et dans les ressources à ta disposition pour choisir les activités, les exercices et les actions qui te permettront d’améliorer ou de maintenir ta condition physique en plus d’atteindre les objectifs que tu as ciblés. Note les ressources consultées lors de ta recherche (nom, hyperlien, etc.). Assure-toi qu’il s’agit de ressources fiables. Inscris tes choix d’activités, d’exercices et d’actions.</p>
                </Instructions>
              <Survey.Survey model={survey} onComplete={this.onComplete} />
              {newStateResults.length ?
              <div className="col-12 col-sm-6 px-0">
                <button className="btn btn-outline-purple px-3 mb-4 w-100" onClick={() => this.props.setActivitiesSection(results)}><i className="fas fa-times"></i> Annuler</button>
              </div> : ""}
             </Wrapper>
    }

    return view;
  }

  render() {

    return (
      <Wrapper>
        <AppNav section="Journal" auth={this.props.auth} />
        <main className="container">
          <SurveySteps section={2} path={journalSectionPath} />
          <div className="box" id="main">
            <h2 className="surveySectionTitle surveySectionRed" id="focusTop" tabIndex="-1">J'explore et je choisis</h2>
          </div>
          {this.props.explore.results !== null ? this.exploreSectionHandler(this.props.explore.currentView) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    explore: state.explore,
    userAuth: state.userAuth
  }
}

function mapDispatchToProps(dispatch){
  return {
    setNewActivities: (data, accessToken, bonjour_person_id) => {
      let headers = {
        Authorization: 'Bearer ' + accessToken,
        "For-Student": bonjour_person_id,
        "Content-Type": "application/json"
      }
      axios.put(process.env.REACT_APP_STORAGEAPI+'explore', data.results, {headers: headers}).then(res => {
        dispatch({ type: constants.SET_ACTIVITIES, payload: data })
      }).catch(function (error) {
      });
    },
    setActivitiesSection: (section) => {
      dispatch({ type: constants.SET_ACTIVITIES_SECTION, payload: section })
      if(document.getElementById("focusTop")) {
        document.getElementById("focusTop").focus();
      }
    },
    getExploreResults: (accessToken) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'explore', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(res => {
        dispatch({ type: constants.GET_EXPLORE_RESULTS, payload: res.data })
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({ type: constants.GET_EXPLORE_RESULTS, payload: [] })
        }
      });
    }
  }
}

Explore.propTypes = {
  explore: PropTypes.object.isRequired,
  setNewActivities: PropTypes.func.isRequired,
  setActivitiesSection: PropTypes.func.isRequired
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(Explore));
