import React, {Component} from 'react';
import NavBar from '../../AppNav/NavBar';
import SideNav from '../../AppNav/SideNav';
import Logo from '../../AppNav/Logo';
import Wrapper from '../../Wrapper/Wrapper';
import Modal from '../Journal/Modal/Modal';
import JournalObjectives from '../../ResultsList/JournalObjectives'
import NavEnseignant from './NavEnseignant'
import axios from 'axios'
import 'survey-react/survey.css';
import moment from 'moment';
import { connect } from 'react-redux'
import constants from '../../../constants'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const results = "results";
const history = "history";

const teacher = "teacher";
const objectifs = "objectifs";


class ObjectifsEnseignant extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentView: "results", // results, history
    }
  }

  componentDidMount() {

    let accessToken = this.props.auth.accessToken;

    // Gets student info from the location state
    const { studentFirstName, studentLastName, studentId } = this.props.location.state;

    // Sets student info in the state
    this.setState({studentFirstName: studentFirstName, studentLastName: studentLastName, studentId: studentId})

    // Gets student data from database
    this.props.getAllObjectifs(accessToken, studentId)
  }

  objectifsSectionHandler(section) {

    let view = null;

    // Getting results from state
    let allStateResults = this.props.objectifs.results;

    // Creating new array that will contain state results with new formating
    let newStateResults = [];

    // Looping through all state results
    for(var i=0; i<allStateResults.length; i++) {
      // Creating new array that will contain state objectives with new formating
      let newStateContent = []
      // Looping to add objectives of current result in the array
      for(var n=0; n<allStateResults[i].objectifs.length; n++){
        newStateContent.push(allStateResults[i].objectifs[n]);
      }
      // Adding date and objectives of current result in the array
      newStateResults.push(
        {
          date: moment(allStateResults[i].timestamp).format("LL"),
          objectifs: newStateContent
        }
      );
    }

    // Getting last item of array which is the latest entry
    let onlyCurrentRes = [newStateResults[newStateResults.length - 1]];

    // Reversing array items for display purposes
    let desAllStateResults = newStateResults.reverse();

    let modalView = <div className="box p-0" id="main">
                      <div className="row my-4">
                        <div className="col-9 my-auto">
                          <h2 className="surveySectionTitle surveySectionRed d-inline px-2">Objectifs SMART</h2>
                        </div>
                        <div className="col-3 text-right">
                          <button type="button" className="btn p-0" title="Directives objectifs SMART - fenêtre surgissante" data-toggle="modal" data-target="#objectifsSMART">
                            <span className="far fa-question-circle fa-2x p-1 purpleColor px-2"></span>
                          </button>
                        </div>
                      </div>
                    </div>;

    if(section === history) {
      view = <Wrapper>
              {modalView}
              {desAllStateResults.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
              <div className="row">
                <div className="col-12 col-md-6">
                  <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(results)}><i className="fas fa-chevron-left mr-2"></i> Revenir aux objectifs</button>
                </div>
              </div>
             </Wrapper>;
    } else if(section === results) {
      if(typeof onlyCurrentRes[0] !== 'undefined'){
        if(newStateResults.length >= 2){
          view = <Wrapper>
                  {modalView}
                  {onlyCurrentRes.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <button className="btn btn-outline-purple px-3 my-4 w-100" onClick={() => this.props.setObjectifsSection(history)}><i className="fas fa-history mr-2"></i> Consulter l'historique de l'élève</button>
                    </div>
                  </div>
                 </Wrapper>;
        } else {
          view = <Wrapper>
                  {modalView}
                  {onlyCurrentRes.map((item, index) => <JournalObjectives results={item} index={index} key={index} />)}
                 </Wrapper>;
        }
      } else {
        view = <div className="my-4">
                <div className="box my-3 text-center">
                  L'élève n'a pas encore créé d'objectifs.
                </div>
              </div>
      }
    }

    return view;

  }

  render() {

    // Returns the last part of the url which is the page name
    const path = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);

    const { studentFirstName, studentLastName, studentId } = this.state;

    // Sets the current page section as Results
    let currentSection = results;

    if(this.props.objectifs.currentView === history) {
      currentSection = history;
    } else {
      currentSection = results;
    }

    return (
      <Wrapper>
        <header>
          <NavBar role={teacher} />
          <SideNav role={teacher} />
          <Logo section={objectifs} />
        </header>
        <main className="container">
          <div className="mb-2 mt-5"><Link to="/" aria-label="retour à l'accueil"><i className="fas fa-home mr-2"></i> Accueil</Link></div>
          <div className="box p-3 mb-5 mt-2"><h1 className="m-0 studentHeader">{studentFirstName} {studentLastName}</h1></div>
          <NavEnseignant path={path} student={{firstname: studentFirstName, lastname: studentLastName, id: studentId}} />
            {this.props.objectifs.results !== null ? this.objectifsSectionHandler(currentSection) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
        <Modal type="objectifsSMART" />
      </Wrapper>
    )
  }

}

function mapStateToProps(state) {
  return {
    objectifs: state.objectifs,
    auth: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNewObjectifs: (data) => {
      dispatch({ type: constants.SET_OBJECTIFS, payload: data })
    },
    setObjectifsSection: (section) => {
      dispatch({ type: constants.SET_OBJECTIFS_SECTION, payload: section })
    },
    getAllObjectifs: (accessToken, studentId) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'objectifs', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          "For-Student": studentId
        }
      }).then(res => {
        dispatch({type: constants.GET_ALL_OBJECTIFS, payload: res.data})
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({type: constants.GET_ALL_OBJECTIFS, payload: []})
        }
      });
    }
  }
}


export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(ObjectifsEnseignant));
