import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar'
import SideNav from './SideNav'
import Logo from './Logo';
import ValidatePage from './ValidatePage';
import { withAITracking } from 'react-appinsights';

// Renders all necessary component for the navigation and logos
const AppNav = (props) => {

  // Renders header of app
  // SideNav is the navbar on mobile
  // ValidatePage renders the header image and also the breadcrumbs
  return (
    <header>
      <NavBar role={props.role} />
      <SideNav role={props.role} />
      <Logo section={props.section} />
      <ValidatePage type="img" section={props.section} />
      <div className="container px-0 px-sm-3">
        <ValidatePage type="breadcrumbs" section={props.section} />
      </div>
    </header>
  )

}

AppNav.propTypes = {
  section: PropTypes.string.isRequired
}

export default withAITracking(AppNav);
