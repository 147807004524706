import React, {Component} from 'react';
import NavBar from '../../AppNav/NavBar';
import SideNav from '../../AppNav/SideNav';
import Logo from '../../AppNav/Logo';
import Wrapper from '../../Wrapper/Wrapper';
import 'survey-react/survey.css';
import NavEnseignant from './NavEnseignant'
import { connect } from 'react-redux'
import constants from '../../../constants'
import axios from 'axios'
import gif from '../../../img/IQ5_Animation_Loading_Gris.gif'
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';
import PlanEnseignantResults from './PlanEnseignantResults'
import PlanHistory from '../Journal/PlanHistory'

const results = "results";
const history = "history";

const teacher = "teacher";
const resultats = "résultats";

class PlanEnseignant extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount() {
    let accessToken = this.props.auth.accessToken;

    // Gets student info from the location state
    const { studentFirstName, studentLastName, studentId } = this.props.location.state;

    // Sets student info in the state
    this.setState({studentFirstName: studentFirstName, studentLastName: studentLastName, studentId: studentId})

    // Gets student data from database
    this.props.getPlanResults(accessToken, studentId)
  }

  loadSection(section) {

    let view = null;

    // Getting last result from store
    let data = this.props.plan.results[this.props.plan.results.length-1]

    // Getting student id from route props
    const { studentId } = this.state;

    // Deep copy of plan results from store
    let stateResults = JSON.parse(JSON.stringify(this.props.plan.results));

    // Removing last element because last element is the current plan. We only want the history
    stateResults.pop();

    // Adding plan number to show the plans in the appropriate order
    for(var i=0; i<stateResults.length; i++){
      stateResults[i]["planNumber"] = i+1
    }

    // Reversing data
    stateResults.reverse();

    if(section === results) {
      view = <div id="main">
              <PlanEnseignantResults results={data} studentId={studentId} />
              {this.props.plan.results.length >= 2 ?
                <div className="col-12 col-sm-6 px-0">
                  <button className="btn btn-outline-purple px-3 mb-4 w-100" onClick={() => this.props.changePlanView(history)}><i className="fas fa-history mr-2"></i> Consulter l'historique de l'élève</button>
                </div> :
                ""
              }
            </div>
    } else if(section === history) {
      view = <div id="main">
              {stateResults.map((item,index) => <PlanHistory results={item} key={index} index={index} />)}
              <div className="col-12 col-sm-6 px-0">
                <button className="btn btn-outline-purple px-3 mb-4 w-100" onClick={() => this.props.changePlanView(results)}><i className="fas fa-chevron-left mr-2"></i> Revenir au plan d'entrainement</button>
              </div>
            </div>
    }

    return view;

  }

  render() {

    // Returns the last part of the url which is the page name
    const path = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);

    const { studentFirstName, studentLastName, studentId } = this.state;

    // Sets the current page section as Results
    let currentSection = results;

    if(this.props.plan.currentView === history) {
      currentSection = history;
    } else {
      currentSection = results;
    }

    return (
      <Wrapper>
        <header>
          <NavBar role={teacher} />
          <SideNav role={teacher} />
          <Logo section={resultats} />
        </header>
        <main className="container">
          <div className="mb-2 mt-5"><Link to="/" aria-label="retour à l'accueil"><i className="fas fa-home mr-2"></i> Accueil</Link></div>
          <div className="box p-3 mb-5 mt-2"><h1 className="m-0 studentHeader">{studentFirstName} {studentLastName}</h1></div>
          <NavEnseignant path={path} student={{firstname: studentFirstName, lastname: studentLastName, id: studentId}}/>
          {this.props.plan.results !== null ? this.loadSection(currentSection) : <img className="mx-auto d-block img-fluid" width="200px" src={gif} alt="" />}
        </main>
      </Wrapper>
    )
  }

}

function mapStateToProps(state){
  return {
    plan: state.plan,
    auth: state.userAuth
  }
}

function mapDispatchToProps(dispatch){
  return {
    changePlanView: (section) => {
      dispatch({ type: constants.CHANGE_PLAN_SECTION, payload: section })
    },
    getPlanResults: (accessToken, studentId) => {
      axios.get(process.env.REACT_APP_STORAGEAPI+'journal', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          "For-Student": studentId
        }
      }).then(res => {
        dispatch({ type: constants.SET_PLAN_DATA, payload: res.data })
      }).catch(function (error) {
        if(error.response.status === 404) {
          dispatch({ type: constants.SET_PLAN_DATA, payload: [] })
        }
      });
    }
  }
}

export default withAITracking(connect(mapStateToProps, mapDispatchToProps)(PlanEnseignant));
