import { createStore } from 'redux';
import constants from './constants'

// Application's initial state
const initialState = {
  whoami: {},
  userAuth: {},
  diagnostique: {
    surveyComplete: false,
    results: null
  },
  objectifs: {
    currentView: "results",
    results: null
  },
  explore: {
    currentView: "results",
    results: null
  },
  plan: {
    currentView: "results",
    activeWeek: 1,
    results: null,
    loading: null,
    newPlan: null
  },
  portfolio: {
    posts: null
  }
}

// All reducers for the application
const reducer = (state = initialState, action) => {

  const results = "results";
  const calendar = "calendar";

  switch(action.type) {
    case constants.SET_WHOAMI:
      return Object.assign({}, state, { whoami: action.payload });

    case constants.SET_USERAUTH:
      return Object.assign({}, state, { userAuth: action.payload });

    case constants.CREATE_DIAGNOSTIQUE:
      return Object.assign({}, state, { diagnostique: action.payload });

    case constants.RESET_DIAGNOSTIQUE:
      return Object.assign({}, state, { diagnostique: { surveyComplete: false, results: state.diagnostique.results } });

    case constants.SET_OBJECTIFS:
      return Object.assign({}, state, { objectifs: action.payload });

    case constants.SET_OBJECTIFS_SECTION:
      return Object.assign({}, state, { objectifs: { currentView: action.payload, results: state.objectifs.results } });

    case constants.SET_ACTIVITIES:
      return Object.assign({}, state, { explore: action.payload });

    case constants.SET_ACTIVITIES_SECTION:
      return Object.assign({}, state, { explore: { currentView: action.payload, results: state.explore.results } });

    case constants.GET_RESULTS:
      return Object.assign({}, state, { diagnostique: { results: action.payload } });

    case constants.GET_ALL_OBJECTIFS:
      return Object.assign({}, state, { objectifs: { currentView: state.objectifs.currentView, results: action.payload } });

    case constants.GET_EXPLORE_RESULTS:
      return Object.assign({}, state, { explore: { currentView: state.explore.currentView, results: action.payload } });

    case constants.SET_PLAN_DATA:
      return Object.assign({}, state, { plan: { results: action.payload, currentView: state.plan.currentView, activeWeek: state.plan.activeWeek, loading: action.loading } });

    case constants.CHANGE_PLAN_SECTION:
      return Object.assign({}, state, { plan: { results: state.plan.results, currentView: action.payload, activeWeek: state.plan.activeWeek, loading: state.plan.loading } })

    case constants.CREATE_NEW_PLAN:
      return Object.assign({}, state, { plan: { results: action.payload, currentView: results, activeWeek: state.plan.activeWeek, loading: state.plan.loading } })

    case constants.SET_EMPTY_PLAN:
      return Object.assign({}, state, { plan: { results: action.payload, currentView: calendar, activeWeek: state.plan.activeWeek, loading: state.plan.loading } })

    case constants.PLAN_LOADING:
      return Object.assign({}, state, { plan: { results: state.plan.results, currentView: state.plan.currentView, activeWeek: state.plan.activeWeek, loading: action.payload } })

    case constants.CREATE_PLAN_COMMENT:
      return Object.assign({}, state, { plan: { results: action.payload, currentView: state.plan.currentView, activeWeek: state.plan.activeWeek, loading: state.plan.loading } })

    case constants.SET_PLAN_WEEK:
      return Object.assign({}, state, { plan: { results: state.plan.results, currentView: state.plan.currentView, activeWeek: action.payload, loading: state.plan.loading } })

    case constants.SET_NEW_PLAN:
      return Object.assign({}, state, { plan: { results: state.plan.results, currentView: state.plan.currentView, activeWeek: state.plan.activeWeek, loading: state.plan.loading, newPlan: action.payload } })

    case constants.CREATE_PORTFOLIO_POST:
      return Object.assign({}, state, { portfolio: { posts: action.payload } })

    case constants.SET_PORTFOLIO_POSTS:
      return Object.assign({}, state, { portfolio: { posts: action.payload } })

    case constants.DELETE_POST:
      return Object.assign({}, state, { portfolio: { posts: action.payload } })

    default:
      return state;
  }
}

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
