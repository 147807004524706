import React from 'react';
import { Link } from "react-router-dom";
import { withAITracking } from 'react-appinsights';

const NavEnseignant = (props) => {
  // This component renders the navigation bar when a teacher is viewing a student's data
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-3 mx-auto mb-3">
        <Link className={`btn btn-outline-purple ${props.path === "Resultats" ? "active" : ""} text-center m-0 h-100 w-100 fontSize500`} to={{
          pathname: `/${props.student.firstname+props.student.lastname}/Resultats`,
          state: {
            studentFirstName: props.student.firstname,
            studentLastName: props.student.lastname,
            studentId: props.student.id
          }
        }}>Résultats</Link>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mx-auto mb-3">
      <Link className={`btn btn-outline-purple ${props.path === "Objectifs" ? "active" : ""} text-center m-0 h-100 w-100 fontSize500`} to={{
          pathname: `/${props.student.firstname+props.student.lastname}/Objectifs`,
          state: {
            studentFirstName: props.student.firstname,
            studentLastName: props.student.lastname,
            studentId: props.student.id
          }
        }}>Objectifs SMART</Link>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mx-auto mb-3">
      <Link className={`btn btn-outline-purple ${props.path === "Explore" ? "active" : ""} text-center m-0 h-100 w-100 fontSize500`} to={{
          pathname: `/${props.student.firstname+props.student.lastname}/Explore`,
          state: {
            studentFirstName: props.student.firstname,
            studentLastName: props.student.lastname,
            studentId: props.student.id
          }
        }}>J'explore, je choisis</Link>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mx-auto mb-3">
      <Link className={`btn btn-outline-purple ${props.path === "Plan" ? "active" : ""} text-center m-0 h-100 w-100 fontSize500`} to={{
          pathname: `/${props.student.firstname+props.student.lastname}/Plan`,
          state: {
            studentFirstName: props.student.firstname,
            studentLastName: props.student.lastname,
            studentId: props.student.id
          }
        }}>Plan d'entraînement</Link>
      </div>
    </div>
  )
}

export default withAITracking(NavEnseignant);
